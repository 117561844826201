import { useEffect, useState } from 'react';

const useScrollTracker = (componentId) => {
  const [isScrolledToMiddle, setIsScrolledToMiddle] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const component = document.getElementById(componentId);
      const componentHeight = component.clientHeight;
      const componentMiddle = componentHeight / 3;
      const scrollPosition = window.scrollY || window.pageYOffset;

      setIsScrolledToMiddle(
        scrollPosition >=
          component.offsetTop + componentMiddle - window.innerHeight / 2 &&
          scrollPosition <=
          component.offsetTop + componentMiddle + window.innerHeight / 2
      );
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [componentId]);

  return isScrolledToMiddle;
};

export default useScrollTracker;
