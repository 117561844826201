import "./ProductForm.css";
import { useState, useEffect } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import LearMoreButtonClick from "../../ui/buttons/LearnButtonClick";
//
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
//
import arrow from "../../images/products/arrow.png";
import { Skeleton } from "@mui/material";
import LazyLoad from "react-lazy-load";
//
const ProductsForm = (props) => {
  const url = useSelector((state) => state.api.url);

  const [products, setProducts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const loadMoreHandler = () => {
    setOffset((o) => o + 6);
  };

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      await axios
        .get(`${url}/get-more-products?offset=${offset}&limit=6`)
        .then((res) => {
          if (res.data) {
            const newProducts = JSON.parse(res.data).products;
            if (offset === 0) {
              setProducts(newProducts);
            } else {
              setProducts((prevProjects) => [...prevProjects, ...newProducts]);
            }
            if (newProducts.length < 6) {
              setShowButton(false);
            } else {
              setShowButton(true);
            }
          } else {
            console.log("no data");
          }
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [offset]);

  return (
    <Container className="!pb-10" fluid>
      <Col>
        <Row className="xs:!h-[30vh] !h-[55vh] relative">
          <Card className="!rounded-none !border-none !bg-transparent !max-h-full !p-0">
            <Card.Img
              src={props.content1?.file_path}
              className="!w-full !min-h-full !max-h-full !object-cover !mix-blend-overlay"
            />
            <Card.ImgOverlay className="!max-h-full !w-full">
              <Card.Body
                className="!absolute !top-1/2 !left-1/2 !transform !-translate-x-1/2 !-translate-y-1/2
                !flex-col !items-center !justify-center !w-full"
              >
                <p
                  className="!text-[#FDCF00] !m-0 !font-bold xs:!leading-10 !leading-[70px]
              xs:!text-[20px] sm:!text-[30px] md:!text-[40px] !text-[50px]"
                >
                  {props.content1?.title}
                </p>
                <p className="text-white xs:!text-[17px] !text-[31px] !mt-3 !w-full">
                  {props.content1?.subtitle}
                </p>
              </Card.Body>
            </Card.ImgOverlay>
          </Card>
        </Row>
        <Row className="xs:!w-full !w-[80%] !m-auto !mt-10">
          <div className="!grid !grid-cols-1 !gap-0">
            {products?.map((p, i) => (
              <Link key={i} to={`/products/${p.slug}`}>
                <Row
                  className={`card-row !relative xs:!h-auto sm:!h-auto md:!h-auto !h-[480px] !max-h-[480px] !flex
                  ${i % 2 === 0 ? "" : "!flex-row-reverse"} ${
                    i > 6 ? "fade-in" : ""
                  }`}
                >
                  <Col xs={6} md={6} className="!max-h-full !p-0">
                    <div className="!relative !bg-transparent !rounded-none !h-full !w-full !border-none">
                      <LazyLoad className="!h-full">
                        <img
                          src={p.main_image}
                          alt={p.slug}
                          className="xs:!h-[175px] sm:!h-[250px] md:!h-[331px] lg:!h-[479px] !h-full !max-h-full !min-h-full !max-w-full !min-w-full !object-cover"
                        />
                      </LazyLoad>
                      <div
                        className={`${i % 2 === 0 ? "arrow" : "arrow-reverse"}`}
                      >
                        <img
                          src={arrow}
                          alt="design"
                          className={`${i % 2 === 0 ? "" : "!rotate-180"}`}
                        />
                      </div>
                      <div className="overlay"></div>
                    </div>
                  </Col>
                  <Col
                    xs={6}
                    md={6}
                    className="!p-0 !flex !items-center !justify-center"
                  >
                    <div className="!grid !place-items-center xs:!px-1 sm:!px-3 !px-6">
                      <h5 className="xs:!text-[15px] sm:!text-[20px] md:!text-[25px] !text-[30px] !text-[#FDCF00] !capitalize">
                        {p.title}
                      </h5>
                      <h2 className="xs:!leading-4 !leading-7 xs:!text-[12px] sm:!text-[17px] !text-[18px] !text-white !capitalize">
                        {p.brief_description}
                      </h2>
                    </div>
                  </Col>
                </Row>
              </Link>
            ))}
            {isLoading && (
              <div className="!flex !items-center !justify-center">
                <Row
                  className={`!relative !w-full xs:!h-auto sm:!h-auto md:!h-auto !h-[480px] !max-h-[480px] !flex`}
                >
                  <Col xs={6} md={6} className="!max-h-full !p-0">
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.63)",
                        width: "100%",
                        height: "100%",
                        borderRadius: "20px",
                      }}
                    />
                  </Col>
                  <Col
                    xs={6}
                    md={6}
                    className="!p-0 !flex !flex-col !items-center !justify-center"
                  >
                    <div className="!w-full">
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: "2rem",
                          backgroundColor: "rgba(255, 255, 255, 0.63)",
                          width: "90%",
                          margin: "auto",
                        }}
                      />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          fontSize: "2rem",
                          backgroundColor: "rgba(255, 255, 255, 0.63)",
                          width: "40%",
                          margin: "auto",
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Row>
        {products?.length >= 6 && showButton && (
          <LearMoreButtonClick
            className="!m-auto !mt-10"
            onClick={loadMoreHandler}
          >
            Load More
          </LearMoreButtonClick>
        )}
      </Col>
    </Container>
  );
};

export default ProductsForm;
