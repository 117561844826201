import PortfolioForm from "../components/layout/portfolio/PortfolioForm";
import { useEffect } from "react";

const PortfolioPage = (props) => {
  useEffect(() => {
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", "test description");
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute(
        "content",
        "https://chat.openai.com/images/chatgpt-share-og.png"
      );
  }, []);
  return <PortfolioForm content1={props.content1} content3={props.content3} />;
};

export default PortfolioPage;
