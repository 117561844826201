import Card from "react-bootstrap/Card";

const StagesCard = ({ src, title, index }) => {
  return (
    <Card
      style={{
        width: "313px",
        background: "rgba(137, 115, 15, 1)",
        border: "3px solid #bbb",
        borderRadius: "40px",
        padding: "10px 10px",
      }}
      className={`hover:!bg-[#FDCF00] !h-[317px] hover:!text-white !text-[#FDCF00]`}
    >
      <Card.Img
        variant="top"
        src={src}
        alt={title}
        className="!h-[210px] !w-full !max-h-[210px] !object-cover !rounded-[40px]"
      />
      <Card.Body className="!flex-col !items-center !justify-center xs:!pt-2 md:!px-0 md:!pt-2 !pt-1">
        <div
          className="xs:!h-[44px] xs:!w-[44px] sm:!h-[44px] sm:!w-[44px] md:!h-[44px] md:!w-[44px] !h-[52px] !w-[52px] !rounded-full !flex !items-center
            !justify-center !m-auto "
          style={{ border: "1px solid #000000" }}
        >
          <div
            className="!rounded-full xs:!h-[34px] xs:!w-[34px] sm:!h-[34px] sm:!w-[34px] md:!h-[34px] md:!w-[34px] !h-[42px] !w-[42px] !bg-black !font-bold
            !flex !items-center !justify-center"
          >
            {index}
          </div>
        </div>
        <div className="!text-center xs:!text-[15px] sm:!text-[16px] md:!text-[15px] !text-[18px] !text-white !font-bold !capitalize xs:!mt-1 sm:!mt-1 md:!mt-0 !mt-0">
          {title}
        </div>
      </Card.Body>
    </Card>
  );
};

export default StagesCard;
