import Card from "react-bootstrap/Card";
const ChoiceCard = (props) => {
  return (
    <Card
      style={{ width: "305px", boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)" }}
      className="!border-none !rounded-[20px] !text-black !min-h-[426px] !max-h-[426px]
      !bg-white hover:!bg-[#FDCF00] hover:!text-white !mb-5 !pt-2"
    >
      <div className="!w-full !grid !place-items-center">
        <div className="!grid !place-items-center !w-[110px] !h-[110px] !rounded-[50%] !border-4 !border-black">
          <div className="!grid !place-items-center !w-[90%] !h-[90%] !bg-black !rounded-[50%]">
            <i className={props.icon} style={{ color: "#ffff", fontSize: "60px" }} />
          </div>
        </div>
      </div>
      <Card.Body>
        <Card.Title className="!font-bold !text-[20px] !text-left">
          {props.title}
        </Card.Title>
        <Card.Text
          className="!font-ligh md:!text-[12px] !text-[15px] !text-left"
          dangerouslySetInnerHTML={{
            __html: props.description,
          }}
        />
      </Card.Body>
    </Card>
  );
};

export default ChoiceCard;
