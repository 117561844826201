import { useState, useEffect } from "react";
//
import AboutUsForm from "../components/layout/home/about-us/AboutUsForm";
import Blogs from "../components/layout/home/blogs/Blogs";
import ContactUs from "../components/layout/home/contact-us/ContactUs";
import ImageSlider from "../components/layout/home/image-slider/ImageSlider";
import GetInTouch from "../components/layout/home/in-touch/GetInTouch";
import OurServices from "../components/layout/home/our-services/OurServices";
import OurTeam from "../components/layout/home/our-team/OurTeam";
import PerfectChoice from "../components/layout/home/perfect-choice/PerfectChoice";
import Portfolio from "../components/layout/home/portfolio/Portfolio";
import Skills from "../components/layout/home/skills/Skills";
import Stages from "../components/layout/home/stages/Stages";
import Technologies from "../components/layout/home/technologies/Technologies";
import Meeting from "../components/layout/home/meeting/Meeting";
//
import loader from "../components/images/loader_no_loop.gif";

const Home = (props) => {
  const sortedSections = props.data?.section_sort?.sort(
    (a, b) => a.order_number - b.order_number
  );

  const [showLoader, setShowLoader] = useState(props.showLoader);
  const [animateLoader, setAnimateLoader] = useState(false);

  useEffect(() => {
    if (!props.showLoader) {
      setAnimateLoader(true);
      const timeout = setTimeout(() => {
        setShowLoader(false);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [props.showLoader]);

  return (
    <>
      {showLoader && (
        <div
          className={`${
            animateLoader ? "swipe-up" : ""
          } loader-overlay !select-none !absolute !left-0 !top-0 !right-0 !bottom-0 !bg-[#101010] !z-[999999] !overflow-hidden`}
        >
          <img
            className="!absolute !left-1/2 !top-[45%] xs:!w-full !transform !-translate-y-1/2 !-translate-x-1/2"
            src={loader}
            alt="loader"
          />
        </div>
      )}
      {!props.showLoader && props.data && (
        <section className="!pb-10">
          <ImageSlider
            content1={props.sliderData.slider_text}
            content2={props.sliderData.slider_images}
            content3={props.sliderData.slider_typing_text}
          />
          <Meeting content={props.data.meeting} />
          {sortedSections.map((section) => {
            switch (section.section_name) {
              case "Projects Section":
                return (
                  <Portfolio
                    key={section.section_name}
                    content1={props.data.projects_categories}
                    content2={props.data.projects}
                  />
                );
              case "About Us Section":
                return (
                  <AboutUsForm
                    key={section.section_name}
                    content={props.data.about_us}
                  />
                );
              case "Perfect Choice":
                return (
                  <PerfectChoice
                    key={section.section_name}
                    content={props.data.perfect_choice}
                  />
                );
              case "Blogs Section":
                return (
                  <Blogs
                    key={section.section_name}
                    content={props.data.blogs}
                  />
                );
              case "Stages":
                return (
                  <Stages
                    key={section.section_name}
                    content={props.data.stages}
                  />
                );
              case "Skills":
                return (
                  <Skills
                    key={section.section_name}
                    content={props.data.skills}
                    content2={props.data.skills_typing_1}
                    content3={props.data.skills_typing_2}
                  />
                );
              case "GetInTouch":
                return <GetInTouch key={section.section_name} />;
              case "Team Members Section ":
                return (
                  <OurTeam
                    key={section.section_name}
                    content={props.data.team}
                  />
                );
              case "Service Section":
                return (
                  <OurServices
                    key={section.section_name}
                    content={props.data.services}
                  />
                );
              case "Technologies Section":
                return (
                  <Technologies
                    key={section.section_name}
                    content={props.data.technologies}
                  />
                );
              case "Contact Us Section":
                return (
                  <ContactUs
                    content={props.data.contact_us}
                    key={section.section_name}
                  />
                );
              default:
                return null;
            }
          })}
        </section>
      )}
    </>
  );
};

export default Home;
