import "./OurServicesBox.css";
import LearMoreButton from "../buttons/LearnMoreButton";

const OurServicesBox = ({ img, title }) => {
  return (
    <div className="xs:!overflow-visible !overflow-hidden !cursor-pointer !relative !rounded-none !bg-transparent !w-full !h-[280px] !border-none">
      <img
        src={img}
        className="!max-h-full !min-h-full !max-w-full !rounded-none xs:!object-cover hover:!scale-110 !transition-transform !ease-in-out !delay-0 !duration-700"
        alt={title}
      />
      <div className="!hidden xs:!block xs:!absolute xs:!left-1/2 xs:!bottom-0 !transform !-translate-x-1/2">
        <LearMoreButton to={`/services/${title}`}>More Details</LearMoreButton>
      </div>
    </div>
  );
};

export default OurServicesBox;
