import classes from "./ImageSlider.module.css";
import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
//
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { stylesActions } from "../../../../store/Styles-slice";
import GsapBg from "./GsapBg";
import LearnMoreButton from "../../../ui/buttons/LearnMoreButton";
import Typed from "react-typed";
const ImageSlider = (props) => {
  const dispatch = useDispatch();
  const isCurrentImage = useSelector((state) => state.styles.currentImageOpend);

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(stylesActions.changeSectionName("Brainkets"));
    }
  }, [inView]);

  return (
    <Container
      className="!overflow-hidden !relative !mx-0 !px-0 !rounded-none xs:!h-[95vh] sm:!h-[65vh] md:!h-[70vh] lg:!h-[100vh] xl:!h-[100vh] xxl:!h-[100vh] !h-[60vh]"
      fluid
      id="imageSlider"
      ref={ref}
    >
      <div className={classes.backgroundImage}></div>
      <div className={classes.bgAnim} />
      <div className={classes.introText}>
        <h1 className="!mb-5 !text-[#fdcf00]">{props.content1.text_one}</h1>
        <h1 className="!max-w-full">
          {props.content1.text_two}
          <Typed
            className="!bg-[#fdcf00] !text-black !min-h-[48px] !h-[48px] md:!text-[20px] md:!w-[20%] !inline"
            strings={props.content3?.map((item) => item.name) || []}
            typeSpeed={40}
            backSpeed={30}
            loop={true}
          />
        </h1>
      </div>
      <LearnMoreButton
        to="contact-us"
        className={`xs:!hidden ${
          isCurrentImage ? "z-[100]" : "!z-[101]"
        } !absolute !top-[70%] md:!top-[77%] xxl:!top-[70%] !left-[5%]`}
      >
        {props.content1?.btn_text}
      </LearnMoreButton>
      <GsapBg images={props.content2} />
    </Container>
  );
};

export default ImageSlider;
