import "./Blogs.css";
import { Container, Row } from "react-bootstrap";
import { useRef, useEffect } from "react";
//
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//
import BlogsCard from "../../../ui/cards/BlogsCard";
import leftArrow from "../../../images/home/blogs/left arrow.svg";
import rightArrow from "../../../images/home/blogs/right arrow.svg";
import ul from "../../../images/ul.svg";
import { useDispatch } from "react-redux";
import { stylesActions } from "../../../../store/Styles-slice";
import { useInView } from "react-intersection-observer";
import LearnMoreButton from "../../../ui/buttons/LearnMoreButton";

const Blogs = (props) => {
  const dispatch = useDispatch();
  const sliderRef = useRef(null);

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(stylesActions.changeSectionName("Blogs"));
    }
  }, [inView]);

  var settings = {
    autoplay: true,
    slidesToShow: props.content.length < 4 ? 2 : 4,
    dots: true,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    centerPadding: "0",
    draggable: true,
    swipe: true,
    touchMove: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const swipeLeft = () => {
    sliderRef.current.slickPrev();
  };
  const swipeRight = () => {
    sliderRef.current.slickNext();
  };

  return (
    <Container
      id="blogs"
      className="!bg-[#0E0E0E] mt-14 !relative !px-4 xs:!px-5 xs:!pb-5 sm:!px-10 md:!px-20 lg:!px-20 xl:!px-20"
      fluid
      ref={ref}
    >
      <div className="absolute top-[10%] right-[5%] flex items-center">
        <img
          src={leftArrow}
          className="mr-3 cursor-pointer"
          alt="design"
          onClick={swipeLeft}
        />
        <img
          src={rightArrow}
          className="cursor-pointer"
          alt="design"
          onClick={swipeRight}
        />
      </div>
      <div className="!absolute !top-[5%] !left-[50%] !transform -translate-x-1/2 !flex !flex-col !justify-start !items-start">
        <h3 className="!uppercase !text-[#ffffff] xs:!text-[25px] !text-[42px] !font-bold !relative">
          Our Blogs
          <img
            className="!mt-3 !absolute !left-[50%] transform -translate-x-1/2 -translate-y-1/2"
            src={ul}
            alt="design"
          />
        </h3>
      </div>
      <Row className="pt-28 xs:pb-0 pb-10">
        <Slider ref={sliderRef} {...settings} className="fj xs:!pt-0 !pt-10">
          {props.content.map((b) => (
            <div key={b.id} className="!pb-5 !pt-4 !w-auto">
              <BlogsCard
                src={b.main_image}
                title={b.cat}
                question={b.title}
                slug={b.slug}
                description={b.brief_description}
                answer={b.description}
              />
            </div>
          ))}
        </Slider>
      </Row>
      <LearnMoreButton to="/blogs" className="xs:!mt-10 !my-5 !m-auto">
        View All
      </LearnMoreButton>
    </Container>
  );
};

export default Blogs;
