import { Container, Row, Col } from "react-bootstrap";
import Typed from "react-typed";
import { useEffect, useState } from "react";
import { stylesActions } from "../../../../store/Styles-slice";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
//
import AOS from "aos";
import "aos/dist/aos.css";

const Skills = (props) => {
  const dispatch = useDispatch();

  const [isIOS, setIsIOS] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(stylesActions.changeSectionName("Skills"));
    }
  }, [inView]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });

    const userAgent = window.navigator.userAgent;
    setIsIOS(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);
  }, []);

  return (
    <Container className="!pt-16" id="skills" ref={ref}>
      <p className="!uppercase !text-center !text-[#fdcf00] xs:!text-[22px] !text-[36px] !leading-[19.2px] !font-semibold">
        {props.content?.section_title}
      </p>
      <Row>
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={6}
          className="!grid !place-items-center"
          data-aos="flip-left"
          data-aos-once="true"
        >
          {props.content?.video && !isIOS && (
            <video
              src={props.content?.video}
              autoPlay={true}
              muted
              loop
              alt="skills"
              width="520px"
              className="!border-4 !border-[#FDCF00] !rounded-xl"
              loading="lazy"
            />
          )}
          {(!props.content?.video || isIOS) && (
            <img
              src={props.content?.main_image}
              className="!w-[520px] !h-[287px] !object-cover"
              alt="skills"
              loading="lazy"
            />
          )}
        </Col>
        <Col>
          <Row className="!flex-col !items-center !justify-start !text-left xs:!mt-5 sm:!mt-5 !mt-10">
            <h3 className="!capitalize !text-white !text-[36px] !leading-[43.2px] !font-bold">
              {props.content?.title}
            </h3>
            <p
              className="!text-white !text-[15px] !leading-[30px] !font-light"
              dangerouslySetInnerHTML={{ __html: props.content?.description }}
            />
          </Row>
          <div className="xl:!mt-4 xxl:!mt-4 !flex !flex-row xs:!items-center xs:!justify-center xl:!justify-evenly xxl:!justify-around !items-center !justify-center !gap-4 lg:!gap-10 xl:!gap-10">
            <div className="!flex !flex-col !justify-center !items-center">
              <div
                className="!bg-[#FDCF00] !rounded-[10px] !relative !z-10 !h-[64px]
              xs:!w-[160px]  mx-0 !w-[222.33px] !grid !place-items-center"
              >
                <p className="!text-white !text-[18px] !m-auto">
                  {props.content?.title_one}
                </p>
              </div>
              <div
                className="!bg-[#1F1F1F] !rounded-[10px] !-mt-2 !relative !z-0
              xs:!w-[160px]  mx-0 !w-[222.33px] !min-h-[150px] !grid !place-items-center"
              >
                <Typed
                  className="!text-white xs:!text-[16px] !text-[18px] !m-auto"
                  strings={props.content2?.map((item) => item.name) || []}
                  typeSpeed={40}
                  backSpeed={30}
                  loop={true}
                />
              </div>
            </div>
            <div className="!flex !flex-col !justify-center !items-center">
              <div
                className="!bg-[#FDCF00] !rounded-[10px] !relative !z-10 !h-[64px]
              xs:!w-[160px]  mx-0 !w-[222.33px] !grid !place-items-center"
              >
                <p className="!text-white !text-[18px] !m-auto">
                  {props.content?.title_two}
                </p>
              </div>
              <div
                className="!bg-[#1F1F1F] !rounded-[10px] !-mt-2 !relative !z-0
              xs:!w-[160px]  mx-0 !w-[222.33px] !min-h-[150px] !grid !place-items-center"
              >
                <Typed
                  className="!text-white xs:!text-[16px] !text-[18px] !m-auto"
                  strings={props.content3?.map((item) => item.name) || []}
                  typeSpeed={40}
                  backSpeed={30}
                  loop={true}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Skills;
