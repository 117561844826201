import "./App.css";
//
import { useState, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { dataActions } from "./store/data-slice";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
//
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import NavBar from "./components/layout/navbar/NavBar";
import Footer from "./components/layout/footer/Footer";
//
import ScrollToTopButton from "./components/scroll/ScrollToTopButton";
import ScrollToTop from "./components/scroll/ScrollToTop";
import { useMediaQuery } from "react-responsive";
//
import Home from "./pages/Home";
import BlogsDetailPage from "./pages/BlogsDetailPage";
import TeamMembersPage from "./pages/TeamMembersPage";
import PortfolioPage from "./pages/Portfolio";
import ServicesPage from "./pages/Services";
import ProductsPage from "./pages/Products";
import AboutUsPage from "./pages/AboutUs";
import SinglePortfolioPage from "./pages/SinglePortfolioPage";
import SingleProductPage from "./pages/SingleProductPage";
import BlogsPage from "./pages/Blogs";
import ServicesDEtailPage from "./pages/ServicesDetailPage";
import ContactUsPage from "./pages/ContactUs";
import BottomNavBar from "./components/ui/bottomNavBar/BottomNavBar";

function App() {
  const dispatch = useDispatch();
  const url = useSelector((state) => state.api.url);

  const location = useLocation();
  const isHomePage = location.pathname.split("/")[1] === "home";

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const getNavBar = async () => {
    const response = await axios.get(`${url}/get-nav-bar`);
    return JSON.parse(response.data);
  };

  const getFooter = async () => {
    const response = await axios.get(`${url}/get-footer`);
    return JSON.parse(response.data);
  };

  const getSliderData = async () => {
    const response = await axios.get(`${url}/get-slider-data`);
    return JSON.parse(response.data);
  };

  const getHomeData = async () => {
    const response = await axios.get(`${url}/get-home-data`);
    dispatch(
      dataActions.setFooterContactData(JSON.parse(response.data).contact_us)
    );
    dispatch(dataActions.setFooterServices(JSON.parse(response.data).services));
    return JSON.parse(response.data);
  };

  const { isLoading: isLoadingSlider, data: sliderData } = useQuery({
    queryKey: ["sliderData"],
    queryFn: getSliderData,
    staleTime: 60000,
  });
  // isLoading, isError, data, error
  const { isLoading: isLoadingHome, data: homeData } = useQuery({
    queryKey: ["homeData"],
    queryFn: getHomeData,
    staleTime: 60000,
    enabled: !!sliderData && !isLoadingSlider,
  });

  const { isLoading: isLoadingNavbar, data: navbarData } = useQuery({
    queryKey: ["navBar"],
    queryFn: getNavBar,
    staleTime: 1000 * 60 * 60 * 5, // 5 hours in milliseconds
  });

  const { isLoading: isLoadingFooter, data: footerData } = useQuery({
    queryKey: ["footer"],
    queryFn: getFooter,
    staleTime: 1000 * 60 * 60 * 5,
  });

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (!isLoadingHome) {
      if (isHomePage) {
        setTimeout(() => {
          setShowLoader(false);
        }, 3000);
      } else {
        setShowLoader(false);
      }
    }
  }, [isLoadingHome]);

  return (
    <div className="App selection:!text-white selection:!bg-black">
      {!showLoader && (
        <header>
          <NavBar isLoading={isLoadingNavbar} items={navbarData} />
        </header>
      )}
      <main>
        {isMobile && <BottomNavBar items={navbarData} />}
        <ScrollToTop />
        <ScrollToTopButton />
        <ToastContainer
          icon={false}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Switch>
          <Route path="/" exact>
            <Redirect to="/home" />
          </Route>
          <Route path="/home" exact>
            <Home
              isLoading={isLoadingHome}
              data={homeData}
              sliderData={sliderData}
              showLoader={showLoader}
            />
          </Route>
          <Route path="/blogs/:title" exact>
            <BlogsDetailPage />
          </Route>
          <Route path="/services/:title" exact>
            <ServicesDEtailPage content1={homeData?.projects_categories} />
          </Route>
          <Route path="/team" exact>
            <TeamMembersPage
              content={homeData?.team}
              content2={homeData?.team_section}
            />
          </Route>
          <Route path="/portfolio" exact>
            <PortfolioPage
              content1={homeData?.projects_categories}
              content3={homeData?.projects_section}
            />
          </Route>
          <Route path="/portfolio/:title" exact>
            <SinglePortfolioPage content1={homeData?.projects_categories} />
          </Route>
          <Route path="/services" exact>
            <ServicesPage content={homeData?.services} />
          </Route>
          <Route path="/products" exact>
            <ProductsPage content1={homeData?.products_section} />
          </Route>
          <Route path="/products/:title" exact>
            <SingleProductPage />
          </Route>
          <Route path="/blogs" exact>
            <BlogsPage content={homeData?.blogs} />
          </Route>
          <Route path="/about-us" exact>
            <AboutUsPage
              content={homeData?.about_us}
              socialMedia={footerData?.social_media}
            />
          </Route>
          <Route path="/contact-us" exact>
            <ContactUsPage content={homeData?.contact_us} />
          </Route>
        </Switch>
      </main>
      {!showLoader && (
        <footer>
          <Footer
            isLoading={isLoadingFooter}
            content={footerData?.content}
            socialMedia={footerData?.social_media}
          />
        </footer>
      )}
    </div>
  );
}

export default App;
