import { Col, Container, Row } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { stylesActions } from "../../../../store/Styles-slice";
import useScrollTracker from "../../../../hooks/useScrollTracker";
const AboutUsForm = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  const isScrolledToMiddle = useScrollTracker("about-us");
  useEffect(() => {
    if (isScrolledToMiddle) {
      dispatch(stylesActions.changeSectionName("about-us"));
    }
  }, [isScrolledToMiddle, dispatch]);
  return (
    <Container className="!bg-[#1F1F1F]" id="about-us" fluid>
      <Container className="xs:!px-0 !px-10 mt-4 pt-10 !text-left">
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className="!relative" data-aos="fade-up" data-aos-once="true">
              <p className="!text-[#fdcf00] !text-center">
                {props.content[0]?.subtitle}
              </p>
              <h3 className="!font-bold !text-[36px] !text-white !text-center">
                {props.content[0]?.title}
              </h3>
              <p
                className="!font-[15px] !text-white"
                dangerouslySetInnerHTML={{
                  __html: props.content[0].description,
                }}
              />
            </div>
          </Col>
          <Col className="!relative" data-aos="fade-left" data-aos-once="true">
            <img
              src={props.content[0]?.main_image}
              alt={props.content[0]?.subtitle}
              className="!w-[450px] !h-[320px] !object-cover !float-right"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default AboutUsForm;
