import { createSlice } from "@reduxjs/toolkit";

const StyleSlice = createSlice({
  name: "styles",
  initialState: {
    sectionName: "Brainkets",
    currentImageOpend: false,
    openMenu: false,
  },
  reducers: {
    changeSectionName(state, action) {
      state.sectionName = action.payload;
    },
    setCurrentImageOpened(state) {
      state.currentImageOpend = !state.currentImageOpend;
    },
    openMenu(state) {
      state.openMenu = !state.openMenu;
    },
  },
});

export const stylesActions = StyleSlice.actions;
export default StyleSlice;
