import "../../layout/home/blogs/Blogs.css";
import { Card } from "react-bootstrap";
import img from "../../../components/images/home/blogs/Group.svg";
import { Link, useHistory } from "react-router-dom";
import LearMoreButton from "../buttons/LearnMoreButton";

const BlogsCard = (props) => {
  const history = useHistory();
  const redirectTo = (t) => {
    history.push(t);
  };
  return (
    <Card
      style={{
        width: "18rem",
        boxShadow: "1px -1px 20px 2px rgba(253, 207, 0, 0.3)",
        WebkitBoxShadow: "1px -1px 35px 2px rgba(253, 207, 0, 0.3)",
        MozBoxShadow: "1px -1px 20px 2px rgba(253, 207, 0, 0.3)",
        background: "linear-gradient(180deg, #2A2C38 35.42%, #5C5C5C 100%)",
        borderBottomColor: "#5C5C5C",
      }}
      className="!text-left !rounded-3xl !h-[530px] overflow-hidden"
    >
      <Card.Img
        onClick={() => {
          redirectTo(`/blogs/${props.slug}`);
        }}
        variant="top"
        src={props.src}
        className="!transform !h-[160px] !object-cover !transition !duration-500 hover:!scale-110 !cursor-pointer"
      />
      <Card.Body className="!w-[265px] !h-[150px] !pb-0">
        <Card.Title className="!text-[12px] !text-white !py-3 !flex">
          <img src={img} alt={props.title} className="!pr-2" /> {props.title}
        </Card.Title>
        <Card.Text
          as={Link}
          to={`/blogs/${props.slug}`}
          className="!font-bold !text-white !text-[21px] !leading-[30px]"
        >
          {props.question}
        </Card.Text>
        <Card.Text className="!mt-4 !font-light !text-[#999999] !text-[14px] !leading-[24px]">
          {props.description}
        </Card.Text>
      </Card.Body>
      <Card.Footer className="!border-t-0 !bg-transparent !pt-0 !pb-4">
        <LearMoreButton to={`/blogs/${props.slug}`}>
          Read More
        </LearMoreButton>
      </Card.Footer>
    </Card>
  );
};

export default BlogsCard;
