import img from "../../images/bk black.svg";

const CustomToast = ({ message }) => {
  return (
    <div className="!flex !items-center !justify-start">
      <img src={img} alt="Custom Toast" />
      <span className="!ml-2">{message}</span>
    </div>
  );
};

export default CustomToast;
