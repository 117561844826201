import { Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import ContactUsForm from "../../../ui/forms/ContactUsForm";
import bg from "../../../images/home/contact-us/bg.jpg";
//
import ul from "../../../images/ul.svg";
import { stylesActions } from "../../../../store/Styles-slice";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";

const ContactUs = ({ content }) => {
  const dispatch = useDispatch();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(stylesActions.changeSectionName("Contact Us"));
    }
  }, [inView]);

  return (
    <Container
      className="!relative mt-14 xs:!px-3 md:!px-3 !px-10 !py-6 !rounded-[30px]"
      id="contact-us"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
      ref={ref}
    >
      <Row>
        <Col className="!text-left" md={5} lg={5} xl={5} xxl={6}>
          <Row className="!flex">
            <p className="!text-[18px] !text-[#FDCF00] !uppercase">
              {content[0]?.title}
            </p>
            <h3 className="!text-[36px] !font-bold !text-white !w-[363.93px] !relative">
              {content[0]?.subtitle}
              <img
                className="!mt-3 !absolute !left-[15%] transform -translate-x-1/2 -translate-y-1/2"
                src={ul}
                alt={content[0]?.subtitle}
              />
            </h3>
          </Row>
          <Row className="md:!pl-2 xs:!grid xs:!place-items-center xs:!mb-3 sm:!grid sm:!place-items-center sm:!mb-3">
            <iframe
              title="Google Map"
              src={content[0]?.map_link}
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
            ></iframe>
          </Row>
          <Row className="mt-2 !w-fit">
            <a
              href="https://www.google.com/maps/dir//Brainkets+Main+street+Tyre/@33.2375015,35.2508562,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x151e876d2e8bb3b7:0x122f4250fa833e2c"
              target="_blank"
              rel="noopener noreferrer"
              className="!text-white !text-lg"
            >
              Get Directions
            </a>
          </Row>
          <Row className="xs:!mt-3 xs:!mb-2 xs:!relative sm:!relative !absolute !bottom-0">
            <div className="!flex-col">
              <p>
                <a
                  className="!text-white text-[18px] !mb-4 !font-semibold !leading-[16px]"
                  href={`tel:${content[0]?.phone_number}`}
                >
                  <i
                    className="fa-solid fa-mobile pr-3"
                    style={{ color: "#fdcf00" }}
                  ></i>{" "}
                  {content[0]?.phone_number}
                </a>
              </p>
              <p className="!text-white text-[18px] !font-semibold !leading-[16px]">
                <i
                  className="fa-sharp fa-solid fa-location-dot pr-3"
                  style={{ color: "#fdcf00" }}
                ></i>{" "}
                {content[0]?.address}
              </p>
              <p className="!text-white text-[18px] !font-semibold !leading-[16px]">
                <i
                  className="fa-solid fa-envelope pr-2"
                  style={{ color: "#fdcf00" }}
                ></i>{" "}
                <a className="!text-white" href={`mailto:${content[0]?.email}`}>
                  {content[0]?.email}
                </a>
              </p>
            </div>
          </Row>
        </Col>
        <Col>
          <ContactUsForm />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
