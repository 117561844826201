import "./OurTeamCard.css";
import { useState } from "react";
import { Skeleton } from "@mui/material";

const OurTeamCard = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className={`main-card ${props.className}`}>
      <img
        src={props.img}
        alt={props.name}
        className={`${imageLoaded ? "main-image" : "!w-0 !h-0"}`} 
        loading="lazy"
        onLoad={handleImageLoad}
      />
      {!imageLoaded && (
        <Skeleton
          variant="rectangular"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 1)",
            height: "15rem",
          }}
          className="main-image"
        />
      )}
      {props.linkedIn && (
        <div className="main-icons">
          <ul className="icons-list">
            <li>
              <a
                href={props.linkedIn}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in cursor-pointer" />
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="main-text">
        <p className="name">{props.name}</p>
        <p className="position">{props.role}</p>
      </div>
    </div>
  );
};

export default OurTeamCard;
