import { Container, Col, Row } from "react-bootstrap";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import classes from "./SinglePortfolio.module.css";
//
import PortfolioCard from "../../ui/cards/PortfolioCard";
import LearMoreButton from "../../ui/buttons/LearnMoreButton";
//
import { Lightbox } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
//
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
//
import ul from "../../images/ul.svg";
//
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs, Controller } from "swiper";
//
import { Skeleton } from "@mui/material";

const SinglePortfolio = ({ content1 }) => {
  const { title } = useParams();

  const url = useSelector((state) => state.api.url);

  const [data, setData] = useState([]);
  const [aditionalImages, setAditionalImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [thumbsSwiper, setThumbsSwiper] = useState();
  const swiper1Ref = useRef(null);
  const swiper2Ref = useRef();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [indexImage, setIndexImage] = useState("");

  useLayoutEffect(() => {
    if (swiper1Ref.current !== null) {
      swiper1Ref.current.controller.control = swiper2Ref.current;
    }
  }, []);

  const getProjectByTitle = async (title) => {
    setIsLoading(true);
    var formData = new FormData();
    formData.append("slug", title);
    await axios
      .post(`${url}/get-project-by-title`, formData)
      .then((response) => {
        setData(JSON.parse(response.data));
        const additionalImages =
          JSON.parse(response.data).aditional_images || [];
        const imagesWithStaticSrc = [
          JSON.parse(response.data).main_content?.file_path,
          ...additionalImages,
        ];
        if (JSON.parse(response.data).main_content.is_widely_images === 1) {
          setAditionalImages(imagesWithStaticSrc);
        } else {
          setAditionalImages(additionalImages);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProjectByTitle(title);
  }, [title]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow:
      data?.aditional_images?.length > 3 ? 4 : data?.aditional_images?.length,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow:
            data?.aditional_images?.length > 3
              ? 5
              : data?.aditional_images?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1524,
        settings: {
          slidesToShow:
            data?.aditional_images?.length > 3
              ? 4
              : data?.aditional_images?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const result = data?.aditional_images?.map((item, i) => {
    return {
      key: i,
      src: item,
    };
  });

  useEffect(() => {
    if (result && data?.main_content?.is_widely_images === 1) {
      result.unshift({
        src: data?.main_content?.file_path,
      });
    }
  }, [result]);

  return (
    <Container className="!pb-10">
      <Lightbox
        open={lightboxOpen}
        index={indexImage}
        close={() => setLightboxOpen(false)}
        slides={result}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
      />
      <Col>
        {((!isLoading && data?.main_content?.is_widely_images === 0) ||
          (!isLoading && data?.aditional_images?.length === 0)) && (
          <>
            <Row className="xs:!h-[40vh] !h-[60vh] xs:!mt-[5%] sm:!mt-[24%] md:!mt-[20%] lg:!mt-[15%] !mt-[10%]">
              <img
                src={data?.main_content?.file_path}
                className="!w-full !max-h-full !object-contain"
                alt="project"
              />
            </Row>
            <Row className="xs:!pl-2 !pl-14 !mt-4">
              <div className="xs:!text-sm !flex !items-center !justify-start !text-lg">
                <Link to="/portfolio" className="!text-white !mr-2">
                  Our Projects
                </Link>{" "}
                <span className="!text-white">/</span>
                <p className="!text-[#FDCF00] !mb-0 !ml-2 !uppercase">
                  {title}
                </p>
              </div>
            </Row>
          </>
        )}
        {isLoading && (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.63)",
              borderRadius: "20px",
              margin: "auto",
            }}
            className="xs:!h-[40vh] !h-[60vh] sm:!mt-[24%] md:!mt-[20%] lg:!mt-[15%] !mt-[10%]"
            width={"80%"}
          />
        )}
        {data?.aditional_images?.length > 0 && (
          <Row
            className={`${
              data?.main_content?.is_widely_images === 1 ? "!mt-32" : "!mt-4"
            } !relative !m-auto xs:!mt-10 sm:!mt-16 md:!mt-24 xs:!w-full !w-[80%]`}
          >
            {data?.main_content?.is_widely_images === 1 && (
              <>
                <Swiper
                  onSwiper={(swiper) => {
                    if (swiper1Ref.current !== null) {
                      swiper1Ref.current = swiper;
                    }
                  }}
                  spaceBetween={10}
                  slidesPerView={1}
                  navigation={true}
                  thumbs={{
                    swiper:
                      thumbsSwiper && !thumbsSwiper.destroyed
                        ? thumbsSwiper
                        : null,
                  }}
                  modules={[FreeMode, Navigation, Thumbs, Controller]}
                  className="w-[848px] h-[420px]"
                >
                  {aditionalImages?.map((m, i) => (
                    <SwiperSlide
                      key={i}
                      onClick={() => {
                        setLightboxOpen(true);
                        setIndexImage(i);
                      }}
                    >
                      <img
                        src={m}
                        className="!w-full !h-full !object-contain"
                        alt="portfolio"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Swiper
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={8}
                  watchSlidesProgress
                  touchRatio={0.2}
                  lazy="true"
                  slideToClickedSlide={true}
                  onSwiper={setThumbsSwiper}
                  modules={[Navigation, Thumbs, Controller]}
                  className="!flex !items-center !justify-center xs:!mt-[10px] !mt-[20px]"
                >
                  {aditionalImages.map((m, i) => (
                    <SwiperSlide key={i}>
                      <img
                        src={m}
                        className="!h-full !w-full !object-cover !cursor-pointer"
                        alt="portfolio"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            )}
            {data?.main_content?.is_widely_images === 0 && (
              <Slider {...settings}>
                {aditionalImages?.map((m, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      setLightboxOpen(true);
                      setIndexImage(i);
                    }}
                  >
                    <img
                      src={m}
                      alt={i}
                      className="xs:!w-[80%] sm:!w-[80%] md:!w-[80%] xxl:!w-[95%] xl:!w-[95%] lg:!w-[80%] !w-auto !h-[550px] !min-h-full !object-cover !m-auto"
                    />
                  </div>
                ))}
              </Slider>
            )}
          </Row>
        )}
        <Row className="!m-auto xs:!px-0 sm:!px-0 md:!px-10 !px-16 xs:!mt-8 !mt-8 !w-[90%]">
          {!isLoading && data.main_content?.description && (
            <div
              className={classes.ckDescription}
              dangerouslySetInnerHTML={{
                __html: data.main_content?.description,
              }}
            />
          )}
          {isLoading && (
            <div className="!flex !flex-col">
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  borderRadius: "20px",
                }}
                height={300}
              />
            </div>
          )}
        </Row>
        {data?.options?.length > 0 && (
          <Row className="!m-auto xs:!px-0 sm:!px-0 md:!px-10 !px-16 xs:!mt-8 !mt-8 xs:!w-full !w-[90%]">
            {!isLoading && (
              <div className={classes.options}>
                {data?.options.map((o, i) => {
                  return (
                    <div key={i} className={`${i !== 0 ? "mt-3" : ""}`}>
                      <p className="!mb-0 xs:!flex-col !flex !items-start !justify-start">
                        <span>{o.data_key} </span>
                        <span className="xs:!mt-1 sm:!mt-3">{o.value}</span>
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
            {isLoading && (
              <div className="!flex !flex-col">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    borderRadius: "20px",
                  }}
                  height={300}
                />
              </div>
            )}
          </Row>
        )}
        {data.video && (
          <Row className="!m-auto xs:!px-0 sm:!px-0 md:!px-10 !px-16 xs:!mt-8 !mt-14 !w-full">
            {!isLoading && (
              <video
                src={data.video.file_path}
                autoPlay={true}
                controls={true}
                muted
                loop
                alt="project video"
                className="xxl:!h-[500px] !object-cover"
              />
            )}
            {isLoading && (
              <div className="!flex !flex-col">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    borderRadius: "20px",
                  }}
                  height={300}
                />
              </div>
            )}
          </Row>
        )}
        <Row>
          <LearMoreButton
            to="/contact-us"
            className="!mt-10 !w-[170px] !m-auto"
          >
            Contact Us
          </LearMoreButton>
        </Row>
        <Row className="!relative xs:!mt-3 !mt-10 xs:!px-2">
          <div className="!text-left !my-5 !ml-10">
            <p className="!text-white !text-[36px] !w-max !font-bold !leading-[40px] !relative">
              Other Projects
              <img
                className="!mt-3 !absolute !left-[18%] transform -translate-x-1/2 -translate-y-1/2"
                src={ul}
                alt="design"
              />
            </p>
          </div>
          {!isLoading && data?.other_projects && (
            <Swiper
              slidesPerView={4}
              loop={true}
              navigation={true}
              grabCursor={true}
              modules={[Navigation]}
              breakpoints={{
                200: {
                  slidesPerView: 1,
                  spaceBetween: 60,
                },
                460: {
                  slidesPerView: 2,
                  spaceBetween: 60,
                },
                1000: {
                  slidesPerView: 3,
                  spaceBetween: 60,
                },
                1300: {
                  slidesPerView: 4,
                  spaceBetween: 60,
                },
                1900: {
                  slidesPerView: 4,
                  spaceBetween: 60,
                },
              }}
            >
              {data?.other_projects.map((p, i) => {
                const categoryIds = p.r_project_categories_id?.split(", ");
                const catName = categoryIds
                  ?.map((categoryId) => {
                    const category = content1?.find(
                      (cat) => cat.id == categoryId
                    );
                    return category ? category.category_name : "";
                  })
                  .join(", ");
                return (
                  <SwiperSlide
                    key={i}
                    className="!flex !items-center !justify-center"
                  >
                    <Link
                      to={`/portfolio/${p.slug}`}
                      key={i}
                      className="!w-min !block"
                    >
                      <PortfolioCard
                        title={p.title}
                        catName={catName}
                        filePath={p.path}
                        fileName={p.file_name}
                      />
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
          {isLoading && (
            <div className="!flex !items-center !justify-center">
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  borderRadius: "20px",
                  margin: "auto",
                }}
                height={300}
                width={280}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                className="xs:!hidden"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  borderRadius: "20px",
                  margin: "auto",
                }}
                height={300}
                width={280}
              />
            </div>
          )}
        </Row>
      </Col>
    </Container>
  );
};

export default SinglePortfolio;
