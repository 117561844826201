import "./NavBar.css";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
//
import logoLight from "../../images/main_logo_light.svg";
import bk from "../../images/bk.svg";
import { useSelector } from "react-redux";
import { NavLink as Link } from "react-router-dom";

const NavBar = (props) => {
  const location = useLocation();
  const history = useHistory();
  const isHomePage = location.pathname.split("/")[1] === "home";
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const [isNavOpened, setIsNavOpened] = useState(false);
  const [isNavTop, setIsNavTop] = useState(true);

  const scrollHandler = () => {
    if (window.screen.width < 600 && window.scrollY > 700) {
      document.getElementById("navMob").classList.remove("open");
      setIsNavTop(false);
    } else {
      setIsNavTop(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);
  //scroll
  const handleClick = () => {
    document.getElementById("navMob").classList.remove("open");
    setIsNavOpened(false);
  };
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const navMobRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (navMobRef.current && !navMobRef.current.contains(event.target)) {
        document.getElementById("navMob").classList.remove("open");
        setIsNavOpened(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOpenNav = () => {
    document.getElementById("navMob").classList.toggle("open");
    if (document.getElementById("navMob").classList.contains("open")) {
      setIsNavOpened(true);
    } else {
      setIsNavOpened(false);
    }
  };

  const [shouldAnimate, setShouldAnimate] = useState(false);
  const currentSection = useSelector((state) => state.styles.sectionName);

  useEffect(() => {
    setShouldAnimate(true);

    const timer = setTimeout(() => {
      setShouldAnimate(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [currentSection]);

  if (!isMobile) {
    return (
      <Container
        className={`sm:!py-0 md:!py-2 !py-4 navbarm ${
          isSticky ? "sticky" : ""
        }`}
      >
        <Row>
          <Col md={12} lg={3} className={`!grid !place-items-center`}>
            <img
              onClick={() => history.push("/home")}
              src={logoLight}
              alt="Brainkets"
              className="!m-auto !cursor-pointer"
            />
          </Col>
          <Col className="!pt-0">
            <ul className="!flex !flex-row !items-center !justify-evenly !mb-0">
              {props.items?.map((item, i) => {
                if (item.show_in_nav) {
                  if (item.link.startsWith("https://")) {
                    return (
                      <a
                        href={`${item.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={i}
                      >
                        <li
                          className={`sm:text-[12px] !cursor-pointer ${
                            item.is_button
                              ? "!bg-[#fdcf00] !text-black hover:!text-white !rounded-lg sm:!p-1 md:!p-1 !p-3"
                              : "navI"
                          }`}
                        >
                          {item.title}
                        </li>
                      </a>
                    );
                  } else {
                    return (
                      <Link
                        activeClassName="!text-[#fdcf00]"
                        to={`/${item.link}`}
                        key={i}
                        className={`sm:text-[12px] !cursor-pointer ${
                          item.is_button
                            ? "bg-[#fdcf00] text-black hover:text-white rounded-lg p-3"
                            : "navI"
                        }`}
                      >
                        <li>{item.title}</li>
                      </Link>
                    );
                  }
                }
              })}
            </ul>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <div
        className={
          isNavTop
            ? "fixed left-0 right-0 z-[500] h-[70px]"
            : "navBg fixed left-0 right-0 z-[500] h-[70px]"
        }
      >
        <div
          ref={navMobRef}
          className={
            isNavOpened
              ? "!relative !left-[20px] !top-[10px] !p-[6px] !h-[45px] !w-[50px] !grid !items-center !rounded-full"
              : "navBtnPulse !relative !left-[20px] !top-[10px] !p-[6px] !h-[45px] !w-[50px] !grid !items-center !rounded-full"
          }
          style={{ backgroundColor: !isNavOpened ? "rgba(0, 0, 0, 0.6)" : "" }}
        >
          <img
            src={bk}
            alt="Brainkets"
            className="!h-[40%] !z-[999999]"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            onClick={handleOpenNav}
          />
          <div id="navMob" className="nav-list">
            <ul className="!p-0 !flex !flex-col !gap">
              <li
                className="!flex !flex-col-reverse !gap-1 !items-center !rounded-full !m-2 !z-0"
                onClick={() => {
                  history.push("/");
                  document.getElementById("navMob").classList.remove("open");
                  setIsNavOpened(false);
                }}
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  visibility: "hidden",
                }}
              >
                <img
                  src={bk}
                  alt="Brainkets"
                  className="!p-[6px] !h-[40px] !w-[35px]"
                />
              </li>
              {props.items?.map((item, i) => {
                if (item.show_in_nav) {
                  if (item.link.startsWith("https://")) {
                    return (
                      <a
                        className="!text-white"
                        onClick={() => handleClick()}
                        href={`${item.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={i}
                      >
                        <li
                          className={`!flex !flex-col-reverse !gap-2 !items-center ${
                            i === 0 ? "!mt-1" : "!mt-4"
                          }`}
                        >
                          {item.title}
                          <i className={item.icon} style={{ color: "#ffff" }} />
                        </li>
                      </a>
                    );
                  } else if (!item.link.startsWith("https://")) {
                    return (
                      <Link
                        onClick={() => handleClick()}
                        className="!text-white"
                        to={`/${item.link}`}
                        key={i}
                      >
                        <li
                          className={`!flex !flex-col-reverse !gap-2 !items-center ${
                            i === 0 ? "!mt-1" : "!mt-4"
                          }`}
                        >
                          {item.title}
                          <i className={item.icon} style={{ color: "#ffff" }} />
                        </li>
                      </Link>
                    );
                  }
                }
              })}
            </ul>
          </div>
        </div>
        <div className="sectionName">
          <p className={shouldAnimate ? "typing-animation" : ""}>
            {isHomePage ? (
              currentSection
            ) : (
              <Link className="!text-[#fdcf00]" to="/">
                Brainkets
              </Link>
            )}
          </p>
        </div>
      </div>
    );
  }
};

export default NavBar;
