import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import logoLight from "../../images/main_logo_light.svg";
import { Link } from "react-router-dom";

const BottomNavBar = (props) => {
  const [openNav, setOpenNav] = useState(false);

  const openHandler = () => {
    setOpenNav(true);
  };

  const hideHandler = () => {
    setOpenNav(false);
  };

  return (
    <>
      <div
        className="!fixed !z-[200] !bottom-0 !left-0 !right-0 !bg-black !h-[60px]
    !flex !items-center !justify-between !px-4 !pt-2"
      >
        <Link to="/" className="text-[#FDCF00]">
          <i className="fas fa-home !text-[20px]"></i>
          <p className="!mb-0 !text-[14px]">Home</p>
        </Link>
        <Link to="/services" className="text-[#FDCF00]">
          <i className="fa-solid fa-gear !text-[22px]"></i>
          <p className="!mb-0 !text-[14px]">Services</p>
        </Link>
        <Link to="/products" className="text-[#FDCF00]">
          <i className="fas fa-box !text-[22px]"></i>
          <p className="!mb-0 !text-[14px]">Products</p>
        </Link>
        <Link to="/portfolio" className="text-[#FDCF00]">
          <i className="fas fa-tasks !text-[22px]"></i>
          <p className="!mb-0 !text-[14px]">Porjects</p>
        </Link>
        <i
          className="fas fa-bars text-[#FDCF00] !text-[24px]"
          onClick={openHandler}
        ></i>
      </div>
      <Offcanvas
        className="!w-[70vw] !bg-black"
        show={openNav}
        onHide={hideHandler}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link to="/">
              <img src={logoLight} alt="Brainkets" />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="!text-left">
          <ul className="!flex !flex-col !items-start !justify-between !px-0 !mx-0">
            {props.items?.map((item, i) => {
              if (item.show_in_nav) {
                if (item.link.startsWith("https://")) {
                  return (
                    <a
                      href={`${item.link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={i}
                    >
                      <li
                        className={`sm:text-[12px] !cursor-pointer ${
                          item.is_button
                            ? "!bg-[#FDCF00] !text-black !font-semibold !rounded-lg !p-3 !my-3 !text-[20px]"
                            : "!cursor-pointer !text-[#FDCF00] !relative !font-semibold !text-[20px] !my-2"
                        }`}
                        onClick={hideHandler}
                      >
                        {item.title}
                      </li>
                    </a>
                  );
                } else {
                  return (
                    <Link to={`/${item.link}`} key={i}>
                      <li
                        className={`sm:text-[12px] !cursor-pointer ${
                          item.is_button
                            ? "!bg-[#FDCF00] !text-black !font-semibold !rounded-lg !p-3 !my-3 !text-[20px]"
                            : "!cursor-pointer !text-[#FDCF00] !relative !font-semibold !text-[20px] !my-2"
                        }`}
                        onClick={hideHandler}
                      >
                        {item.title}
                      </li>
                    </Link>
                  );
                }
              } else {
                return;
              }
            })}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default BottomNavBar;
