import { configureStore } from "@reduxjs/toolkit";
import stylesSlice from "./Styles-slice";
import apiSlice from "./api-slice";
import dataSlice from "./data-slice";

const store = configureStore({
  reducer: {
    styles: stylesSlice.reducer,
    api: apiSlice.reducer,
    data: dataSlice.reducer,
  },
});

export default store;
