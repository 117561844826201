import { createSlice } from "@reduxjs/toolkit";

const ApiSlice = createSlice({
  name: "api",
  initialState: {
    url: "https://brainketscms.beeflex.net/data", 
  }
});

export const apiActions = ApiSlice.actions;
export default ApiSlice;
