import "./Stages.css";
import { Container, Row, Col } from "react-bootstrap";
//
import StagesCard from "../../../ui/cards/StagesCard";
import ul from "../../../images/ul.svg";
import { useEffect } from "react";
import { stylesActions } from "../../../../store/Styles-slice";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
//
import AOS from "aos";
import "aos/dist/aos.css";

const Stages = (props) => {
  const dispatch = useDispatch();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(stylesActions.changeSectionName("Stages"));
    }
  }, [inView]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <Container className="sbg !bg-[#1F1F1F]" fluid id="stages" ref={ref}>
      <Container className="!pt-0 !pb-6">
        <Col data-aos="zoom-in" data-aos-once="true">
          <Row className="!flex !items-center !justify-center">
            <p className="!text-[18px] !text-[#FDCF00] !uppercase">
              We prepare your projects in 6 stages
            </p>
            <h3 className="xs:!text-[25px] !text-[36px] !font-bold !text-white !relative">
              OUR AGILE DEVELOPMENT APPROACH
              <img
                className="!mt-3 !absolute !left-[50%] transform -translate-x-1/2 -translate-y-1/2"
                src={ul}
                alt="design"
              />
            </h3>
          </Row>
          <Row className="!mt-6 !grid xs:grid-cols-1 grid-cols-2 sm:!grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:!grid-cols-3 xxl:!grid-cols-3 !place-items-center xs:!gap-8 sm:!gap-10 md:!gap-14 !gap-24">
            {props.content?.map((s, i) => (
              <StagesCard
                src={s.main_image}
                index={i.length > 1 ? i + 1 : `0${i + 1}`}
                key={s.id}
                title={s.stage_name}
              />
            ))}
          </Row>
        </Col>
      </Container>
    </Container>
  );
};

export default Stages;
