import { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import LearMoreButtonClick from "../buttons/LearnButtonClick";
import Spinner from "react-bootstrap/Spinner";
//
import { useSelector } from "react-redux";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import CustomToast from "../toast/ToastWithImage";

const ContactUsForm = () => {
  const url = useSelector((state) => state.api.url);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const createMessage = async ({ name, email, subject, message }) => {
    var formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("subject", subject);
    formData.append("message", message);
    formData.append("recaptcha", recaptchaResponse);
    await axios
      .post(`${url}/set-message`, formData)
      .then((res) => {
        if (JSON.parse(res.data).succeeded) {
          toast.success(<CustomToast message={JSON.parse(res.data).message} />);
          formValues.name = "";
          formValues.email = "";
          formValues.subject = "";
          formValues.message = "";
          setRecaptchaChecked(false);
          recaptchaRef.current.reset();
        } else {
          toast.error(<CustomToast message={JSON.parse(res.data).message} />);
        }
      })
      .catch((err) => console.log(err));
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: createMessage,
  });

  const formSubmitHandler = (e) => {
    e.preventDefault();

    const errors = {
      name: validateInput("name", formValues.name),
      email: validateInput("email", formValues.email),
      subject: validateInput("subject", formValues.subject),
      message: validateInput("message", formValues.message),
    };
    setFormErrors(errors);
    // Check if there are any form errors before submitting
    const isValid = Object.values(errors).every((error) => error === "");
    if (isValid) {
      if (recaptchaChecked) {
        mutate({
          name: formValues.name,
          email: formValues.email,
          subject: formValues.subject,
          message: formValues.message,
        });
      } else {
        toast.error(
          <CustomToast message="Please complete the reCAPTCHA verification before submitting the form." />
        );
      }
    }
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    // Validate the input value and update the form values and errors
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateInput(name, value),
    }));
  };

  const validateInput = (name, value) => {
    switch (name) {
      case "name":
        return value.length < 3
          ? "Name must be at least 3 characters long"
          : "";
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Invalid email address";
      case "subject":
        return value.length < 5
          ? "Subject must be at least 5 characters long"
          : "";
      case "message":
        return value.length < 10
          ? "Message must be at least 10 characters long"
          : "";
      default:
        return "";
    }
  };

  const [recaptchaChecked, setRecaptchaChecked] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  const recaptchaRef = useRef(null);

  const recaptchaHandler = (response) => {
    setRecaptchaResponse(response);
    setRecaptchaChecked(true);
  };

  return (
    <Form
      onSubmit={formSubmitHandler}
      className="xs:!px-2 !pt-14 !pb-4 !px-10 !rounded-[15px]"
      style={{
        backgroundColor: "rgba(42, 44, 56, 0.6)",
        border: "3px solid #FFFFFF",
        backdropFilter: "blur(4px)",
      }}
    >
      <Form.Control
        type="text"
        placeholder="Name"
        size="lg"
        name="name"
        value={formValues.name}
        onChange={inputChangeHandler}
        isInvalid={formErrors.name !== ""}
      />
      <Form.Control.Feedback type="invalid">
        {formErrors.name}
      </Form.Control.Feedback>
      <Form.Control
        type="email"
        placeholder="Email"
        className="!mt-4"
        size="lg"
        name="email"
        value={formValues.email}
        onChange={inputChangeHandler}
        isInvalid={formErrors.email !== ""}
      />
      <Form.Control.Feedback type="invalid">
        {formErrors.email}
      </Form.Control.Feedback>
      <Form.Control
        type="text"
        placeholder="Subject"
        className="!mt-4"
        size="lg"
        name="subject"
        value={formValues.subject}
        onChange={inputChangeHandler}
        isInvalid={formErrors.subject !== ""}
      />
      <Form.Control.Feedback type="invalid">
        {formErrors.subject}
      </Form.Control.Feedback>
      <Form.Control
        as="textarea"
        rows="10"
        aria-label="With textarea"
        placeholder="Message"
        className="!mb-2 !mt-5"
        name="message"
        value={formValues.message}
        onChange={inputChangeHandler}
        isInvalid={formErrors.message !== ""}
      />
      <Form.Control.Feedback type="invalid">
        {formErrors.message}
      </Form.Control.Feedback>
      <ReCAPTCHA
        sitekey="6LdI9XgmAAAAAH3GnAVbx80z48QZQBWZroSBLNfy"
        onChange={recaptchaHandler}
        ref={recaptchaRef}
      />
      <LearMoreButtonClick type="submit" disabled={isLoading} className="!m-auto !mt-7">
        {isLoading ? (
          <span className="!flex !items-center !justify-between">
            Loading <Spinner className="!ml-2" size="sm" animation="grow" />
          </span>
        ) : (
          "Send Message"
        )}
      </LearMoreButtonClick>
    </Form>
  );
};

export default ContactUsForm;
