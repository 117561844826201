import "./Footer.css";
import { useRef, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import bg from "../../images/footer_bg.png";
import logo from "../../images/logo2.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import CustomToast from "../../ui/toast/ToastWithImage";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from "react-bootstrap/Spinner";
//
import { stylesActions } from "../../../store/Styles-slice";
import { useInView } from "react-intersection-observer";
//
const Footer = (props) => {
  const url = useSelector((state) => state.api.url);
  const dispatch = useDispatch();

  function getCurrentYear() {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }

  const services = useSelector((state) => state.data.footerServices);
  const contactUsData = useSelector((state) => state.data.footerContactData);

  const [recaptchaChecked, setRecaptchaChecked] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);

  const recaptchaRef = useRef(null);
  const emailRef = useRef(null);

  const recaptchaHandler = (response) => {
    setRecaptchaResponse(response);
    setRecaptchaChecked(true);
  };

  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const createMessage = async (email) => {
    var formData = new FormData();
    formData.append("email", email);
    formData.append("recaptcha", recaptchaResponse);
    await axios
      .post(`${url}/subscribe-newsletter`, formData)
      .then((res) => {
        if (JSON.parse(res.data).succeeded) {
          toast.success(<CustomToast message={JSON.parse(res.data).message} />);
          emailRef.current.value = "";
          setRecaptchaChecked(false);
          recaptchaRef.current.reset();
        } else {
          toast.error(<CustomToast message={JSON.parse(res.data).message} />);
          recaptchaRef.current.reset();
        }
      })
      .catch((err) => console.log(err));
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: createMessage,
  });

  const submitNewsLetterHandler = () => {
    if (recaptchaChecked) {
      if (emailRef.current.value) {
        if (isValidEmail(emailRef.current.value)) {
          mutate(emailRef.current.value);
        } else {
          toast.error(<CustomToast message="Invalid Email" />);
        }
      } else {
        toast.error(<CustomToast message="Email cannot be blank" />);
      }
    } else {
      toast.error(
        <CustomToast message="Please complete the reCAPTCHA verification before submitting to our Newsletter." />
      );
    }
  };

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(stylesActions.changeSectionName("Brainkets"));
    }
  }, [inView]);

  return (
    <Container
      fluid
      className="!mx-0 xs:!px-0 xs:!pb-16 xs:!pl-4 !px-20 !pt-14 !relative !pb-5"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
      ref={ref}
    >
      <Row>
        <Col xs={12} sm={12} md={4}>
          <Row className="!min-h-[112px] !w-fit">
            {props.content && (
              <Link to="/">
                <img
                  src={props.content[0].main_image}
                  alt="Brainkets"
                  className="!w-[176.58px] !h-full !object-contain"
                />
              </Link>
            )}
          </Row>
          <Row>
            {props.isLoading && (
              <p className="!text-left !mt-7 !text-white !text-[16px] !font-normal !leading-[19.2px]">
                Loading...
              </p>
            )}
            {props.content && (
              <p
                className="!text-left !mt-7 !text-white !text-[16px] !font-normal !leading-[19.2px]"
                dangerouslySetInnerHTML={{ __html: props.content[0].text }}
              ></p>
            )}
          </Row>
          <Row>
            {props.socialMedia && (
              <ul className="!flex !flex-row !justify-start !items-start !list-none">
                {props.socialMedia.map((s, i) => (
                  <li key={i}>
                    <a href={s.link} target="_blank" rel="noopener noreferrer">
                      <i
                        className={`${s.icon} !text-white hover:!text-[#FDCF00] !font-bold !mr-6`}
                      ></i>
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </Row>
          <Row>
            <div className="xs:!w-[97%] !w-full">
              <InputGroup className="!relative mb-3 !rounded-[100px] !bg-white !h-[50px]">
                <InputGroup.Text
                  className="!h-full !rounded-[100px] !bg-transparent !border-none !flex !items-center !justify-center"
                  id="basic-addon1"
                >
                  <i className="fa-solid fa-envelope !text-[24px] text-[#999] !text-center"></i>
                </InputGroup.Text>
                <Form.Control
                  type="email"
                  placeholder="Subscribe to our newsletter"
                  aria-label="Subscribe to our newsletter"
                  aria-describedby="basic-addon1"
                  className="placeholder:!text-[15px] !rounded-[100px] !border-none !h-[50px] !pr-[135px] focus:!shadow-none"
                  ref={emailRef}
                />
                <div
                  className={`${
                    isLoading ? "!pointer-events-none !bg-slate-400" : ""
                  } newsLDiv !cursor-pointer !z-50 !absolute !right-2 !top-1/2 !transform !-translate-y-1/2 !h-[30px] !w-[120px] !flex !items-center !justify-between !px-3 !rounded-[100px] !text-black !font-bold`}
                  style={{ background: "rgba(253, 207, 0, 1)" }}
                  onClick={submitNewsLetterHandler}
                >
                  {!isLoading ? (
                    <span className="!flex !items-center !justify-center">
                      Subscribe{" "}
                      <i className="fa-solid fa-arrow-right !ml-1"></i>
                    </span>
                  ) : (
                    <span>
                      Loading{" "}
                      <Spinner className="!ml-2" size="sm" animation="grow" />
                    </span>
                  )}
                </div>
              </InputGroup>
            </div>
            <ReCAPTCHA
              sitekey="6LdI9XgmAAAAAH3GnAVbx80z48QZQBWZroSBLNfy"
              onChange={recaptchaHandler}
              ref={recaptchaRef}
            />
          </Row>
        </Col>
        <Col xs={12} sm={12} md={4} className="xs:!mt-10">
          <div className="flex flex-col items-start justify-start text-left xs:!pl-0 sm:!pl-0 pl-[40%]">
            <p className="!text-[19.87px] !leading-[21px] !font-semibold !text-white">
              Services
            </p>
            {services.map((s, index) => (
              <Link
                className="!text-[15.4px] !text-white !leading-[23.23px] !font-medium !relative links"
                style={{ paddingTop: index === 0 ? "1px" : "10px" }}
                to={`/services/${s.slug}`}
                key={s.id}
              >
                {s.title}
              </Link>
            ))}
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} className="xs:!mt-10 sm:!mt-7">
          <div className="flex flex-col items-start justify-start text-left xs:!pl-0 sm:!pl-0 !pl-[40%]">
            <p className="!text-[19.87px] !text-white !leading-[21px] !font-semibold">
              Contact Us
            </p>
            <Row className="!text-left !pt-1 pl-[5px]">
              <p className="!uppercase !text-white !text-[17.51px] !leading-[10.1px] !font-bold">
                Address
              </p>
              <p className="!text-[15.4px] !text-white !leading-[23.23px] !font-light">
                {contactUsData[0]?.address}
              </p>
            </Row>
            <Row className="!text-left pl-[5px]">
              <p className="!uppercase !text-white !text-[17.51px] !leading-[10.1px] !font-bold">
                EMAIL
              </p>
              <a
                className="!text-[15.4px] !text-white !leading-[23.23px] !font-light !mb-[16px]"
                href={`mailto:${contactUsData[0]?.email}`}
              >
                {contactUsData[0]?.email}
              </a>
            </Row>
            <Row className="!text-left pl-[5px]">
              <p className="!uppercase !text-white !text-[17.51px] !leading-[10.1px] !font-bold">
                CONTACT
              </p>
              <a
                className="!text-[15.4px] !text-white !leading-[23.23px] !font-light"
                href={`tel:${contactUsData[0]?.phone_number}`}
              >
                {contactUsData[0]?.phone_number}
              </a>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="!border-t-4 xs:!pt-1 !pt-7 !mt-5">
        <div className="xs:!flex-col !flex !items-center !justify-between">
          <p className="!text-white !text-[13px] !m-0 !text-left">
            © Copyright {getCurrentYear()} Brainkets. All Rights Reserved
          </p>
          <img src={logo} alt="Brainkets" className="!h-[24px]" />
        </div>
      </Row>
    </Container>
  );
};

export default Footer;
