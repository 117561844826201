import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import classes from "./ServicesDetail.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ar from "../../../images/servicesDetail/arrowRight.svg";
import ul from "../../../images/ul.svg";
import axios from "axios";
import LearMoreButton from "../../../ui/buttons/LearnMoreButton";
import PortfolioCard from "../../../ui/cards/PortfolioCard";
//
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
//
import { Skeleton } from "@mui/material";

const ServicesDetail = ({ content1 }) => {
  const { title } = useParams();
  const url = useSelector((state) => state.api.url);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getServiceByTitle = async (title) => {
    setIsLoading(true);
    var formData = new FormData();
    formData.append("slug", title);
    await axios
      .post(`${url}/get-service-by-title`, formData)
      .then((response) => {
        setData(JSON.parse(response.data));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (title) {
      getServiceByTitle(title);
    } else {
      setIsLoading(true);
    }
  }, [title]);

  return (
    <Container fluid className="bg-[#2A2C38] !pb-10">
      <Col>
        <Row
          className={`${classes.sd} xs:!h-[35vh] sm:!h-[30vh] md:!h-[30vh] !h-[48vh] relative`}
        >
          <div className="xs:!pt-10 !pt-24 xs:!w-full !w-[80%] xs:!mt-8 sm:!mt-10 md:!mt-10 lg:!mt-10 xl:!mt-10 xxl:!mt-10 !flex !items-start !justify-around xs:!justify-between">
            <div className="!flex !flex-col !items-start !justify-start">
              <div className="bg-[#FDCF00] !w-[80px] text-center !rounded-[4px] !p-[5px] text-black font-bold text-[16px]">
                Services
              </div>
              {!isLoading && data?.main_content?.title && (
                <h3 className="!text-left text-white !p-0 mt-4 font-semibold xs:!text-[14px] md:!text-[23px] text-[30px]">
                  {data.main_content?.title}
                </h3>
              )}
              {isLoading && (
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2rem",
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    width: "10rem",
                  }}
                />
              )}
              {!isLoading && data?.main_content?.brief_description && (
                <p className="!text-left text-white !p-0 mt-2 font-semibold xs:!text-[16px] text-[20px]">
                  {data.main_content?.brief_description}
                </p>
              )}
              {isLoading && (
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2rem",
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    width: "10rem",
                  }}
                />
              )}
            </div>
          </div>
        </Row>
        <Row className="xs:!px-0 sm:!px-0 md:!px-10 lg:!px-16 !px-10 !mt-10">
          <Col xs={12} sm={12} md={7} lg={8} xl={8} xxl={8}>
            {data?.aditional_images?.length > 0 && (
              <div>
                <Swiper
                  slidesPerView={1}
                  slidesPerGroup={1}
                  spaceBetween={10}
                  autoplay={true}
                  loop={true}
                  grabCursor={true}
                  pagination={{
                    clickable: true,
                    dynamicBullets: false,
                  }}
                  navigation={false}
                  modules={[Autoplay, Pagination, Navigation]}
                >
                  {data.aditional_images?.map((i, index) => (
                    <SwiperSlide
                      key={index}
                      className="!grid !place-items-center !w-full xs:!h-auto !h-[550px]"
                    >
                      {!isLoading ? (
                        <img
                          src={i}
                          alt="service"
                          className="!w-full !h-full !min-h-full !object-contain !rounded-[20px]"
                        />
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            backgroundColor: "rgba(255, 255, 255, 0.63)",
                          }}
                          width={"100%"}
                          className="xs:!h-auto !h-[550px]"
                        />
                      )}
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
            {data?.aditional_images?.length === 0 && !isLoading && (
              <div
                className={classes.ckDescription}
                dangerouslySetInnerHTML={{
                  __html: data.main_content?.description,
                }}
              />
            )}
            {isLoading && (
              <>
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2rem",
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    width: "20rem",
                  }}
                />
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "4rem",
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    width: "13rem",
                  }}
                />
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2rem",
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    width: "14rem",
                  }}
                />
              </>
            )}
          </Col>
          {data.related_services && (
            <Col md={5} lg={4} xl={4} xxl={4} className="!pt-20 xs:!pt-5">
              <Row className="!flex !items-center !justify-center">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <p className="!m-auto md:!w-[240px] lg:!w-[260px] !w-[360px] text-white xs:!w-[290px] md:!text-[18px] text-[30px]">
                    Check the related services that{" "}
                    <span className="!text-[#FDCF00] !uppercase">
                      Brainkets
                    </span>{" "}
                    provides you{" "}
                  </p>
                </Col>
                <Col className="xs:!mt-4 !mt-8">
                  <img
                    src={ar}
                    alt="design"
                    className="m-auto sm:m-auto transform rotate-90"
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row className="xs:!flex xs:!flex-col-reverse sm:!flex sm:!flex-col-reverse xs:!px-0 sm:!px-0 md:!px-10 lg:!px-16 !px-10 !mt-10">
          <Col
            xs={12}
            sm={12}
            md={7}
            lg={8}
            xl={8}
            xxl={8}
            className="!grid !place-items-center"
          >
            {!isLoading && data.aditional_images.length > 0 && (
              <div
                className={classes.ckDescription}
                dangerouslySetInnerHTML={{
                  __html: data.main_content?.description,
                }}
              />
            )}
            {isLoading && (
              <>
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2rem",
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    width: "20rem",
                  }}
                />
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "4rem",
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    width: "13rem",
                  }}
                />
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "2rem",
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    width: "14rem",
                  }}
                />
              </>
            )}
          </Col>
          <Col md={5} lg={4} xl={4} xxl={4}>
            {!isLoading &&
              data.related_services &&
              data.related_services?.map((r, index) => (
                <div
                  key={index}
                  className="flex items-center justify-start my-4 border-b-[1px] pb-4"
                >
                  <Link to={`/services/${r.slug}`}>
                    <img
                      src={r.main_image}
                      alt="service"
                      className="!min-w-[100px] !min-h-[80px] !max-w-[100px] !max-h-[80px] !rounded-[10px] !object-contain hover:!scale-105"
                    />
                  </Link>
                  <div className="flex flex-col pl-4 text-left">
                    <Link to={`/services/${r.slug}`}>
                      <p className="text-[#FDCF00] text-[20px] leading-5 mb-2 hover:text-[#f1d177]">
                        {r.title}
                      </p>
                    </Link>
                    <p className="text-white text-[14px] leading-4 line-clamp-3">
                      {r.brief_description}
                    </p>
                  </div>
                </div>
              ))}
            {isLoading && (
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  borderRadius: "20px",
                }}
                height={300}
              />
            )}
          </Col>
        </Row>
        {data?.related_projects?.length > 0 && (
          <Row className="!relative xs:!mt-3 !mt-10 xs:!px-2">
            <div className="!text-left !my-5 !ml-10">
              <p className="!text-white !text-[36px] !w-max !font-bold !leading-[40px] !relative">
                Related Projects
                <img
                  className="!mt-3 !absolute !left-[18%] transform -translate-x-1/2 -translate-y-1/2"
                  src={ul}
                  alt="design"
                />
              </p>
            </div>
            {!isLoading && data?.related_projects && (
              <Swiper
                slidesPerView={4}
                loop={true}
                navigation={true}
                grabCursor={true}
                modules={[Navigation]}
                breakpoints={{
                  200: {
                    slidesPerView: 1,
                    spaceBetween: 60,
                  },
                  460: {
                    slidesPerView: 2,
                    spaceBetween: 60,
                  },
                  1000: {
                    slidesPerView: 3,
                    spaceBetween: 60,
                  },
                  1300: {
                    slidesPerView: 4,
                    spaceBetween: 60,
                  },
                  1900: {
                    slidesPerView: 4,
                    spaceBetween: 60,
                  },
                }}
              >
                {data?.related_projects.map((p, i) => {
                  const categoryIds = p.project_categories?.split(", ");
                  const catName = categoryIds
                    ?.map((categoryId) => {
                      const category = content1?.find(
                        (cat) => cat.id == categoryId
                      );
                      return category ? category.category_name : "";
                    })
                    .join(", ");
                  return (
                    <SwiperSlide
                      key={i}
                      className="!flex !items-center !justify-center"
                    >
                      <Link
                        to={`/portfolio/${p.slug}`}
                        key={i}
                        className="!w-min !block"
                      >
                        <PortfolioCard
                          title={p.title}
                          catName={catName}
                          filePath={p.path}
                          fileName={p.file_name}
                        />
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
            {isLoading && (
              <div className="!flex !items-center !justify-center">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    borderRadius: "20px",
                    margin: "auto",
                  }}
                  height={300}
                  width={280}
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  className="xs:!hidden"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.63)",
                    borderRadius: "20px",
                    margin: "auto",
                  }}
                  height={300}
                  width={280}
                />
              </div>
            )}
          </Row>
        )}
        <Row>
          <LearMoreButton to="/contact-us" className="!mt-5 !w-[170px] !m-auto">
            Contact Us
          </LearMoreButton>
        </Row>
      </Col>
    </Container>
  );
};

export default ServicesDetail;
