import Card from "react-bootstrap/Card";
import { Skeleton } from "@mui/material";

const Meeting = (props) => {
  return (
    <Card className="!bg-[#FDCF00] !h-[125px] !rounded-none !bg-transparent !border-none !mx-0">
      <div className="!bg-[#FDCF00] !h-full !flex xs:!flex-col sm:!flex-col md:!flex-col !items-center !justify-around !text-black">
        <div className="xs:!w-full sm:!w-full md:!w-full !w-1/2">
          {props.content[0].title && (
            <h3 className="xs:!text-[23px] sm:!text-[30px] !text-center !text-[35px] !leading-[52px] !font-bold">
              {props.content[0].title}
            </h3>
          )}
          {!props.content[0].title && (
            <Skeleton
              variant="text"
              sx={{
                fontSize: "2rem",
                backgroundColor: "rgba(255, 255, 255, 0.63)",
                width: "20rem",
              }}
            />
          )}
        </div>
        <div className="xs:!w-[97%] sm:!w-[80%] md:!w-[60%] lg:!w-[40%] xl:!w-[40%] !w-[20%] !relative !flex !items-center !justify-center">
          <div className="shadowBtn !flex !items-center !justify-center !cursor-pointer !w-full !bg-white !z-10 !font-semibold !border-black !p-2 !border-2 !rounded-[10px] !text-[20px]">
            <a
              href="https://wa.me/+96176002661"
              target="_blank"
              rel="noopener noreferrer"
              className="!text-black"
            >
              {props.content[0].btn_txt}
            </a>
            {/* <i className="fab fa-whatsapp !font-bold !text-[18px] !text-[#FDCF00]"></i> */}
          </div>
          <div className="!bg-black !w-[97%] !h-[50px] !absolute !left-2 !right-2 !top-0 !rounded-[10px]"></div>
        </div>
      </div>
    </Card>
  );
};

export default Meeting;
