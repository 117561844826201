import "./LearnMoreButton.css";
const LearMoreButtonClick = (props) => {
  return (
    <button
      type={props.type}
      disabled={props.disabled}
      onClick={props.onClick}
      className={`custom-btn2 btn-15 ${props.className}`}
    >
      {props.children}
    </button>
  );
};

export default LearMoreButtonClick;
