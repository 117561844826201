import Card from "react-bootstrap/Card";

const PortfolioCard = ({ filePath, fileName, title, catName }) => {
  return (
    <Card
      style={{
        width: "18rem",
        height: "300px",
        textAlign: "left",
        overflow: "hidden",
      }}
    >
      <Card.Img
        variant="top"
        src={`${filePath}${fileName}`}
        className="!max-h-[200px] !h-[200px] !object-cover !transform hover:!scale-110 !transition-transform !ease-in-out !duration-500"
      />
      <Card.Body className="!h-min !pt-3 !pl-3">
        <Card.Title className="!capitalize !text-[#fdcf00] !font-semibold !min-h-[47px]">
          {title}
        </Card.Title>
        <Card.Text className="!text-black font-semibold !text-[18px]">
          {catName}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default PortfolioCard;
