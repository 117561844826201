import "./PerfectChoice.css";
import { useEffect } from "react";
import ChoiceCard from "../../../ui/cards/ChoiceCard";
import { Col, Container, Row } from "react-bootstrap";
import ul from "../../../images/ul.svg";
import logo from "../../../images/main_logo_light.svg";
import { useDispatch } from "react-redux";
import { stylesActions } from "../../../../store/Styles-slice";
import { useInView } from "react-intersection-observer";
const PerfectChoice = (props) => {
  const dispatch = useDispatch();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(stylesActions.changeSectionName("Your Perfect Choice"));
    }
  }, [inView]);

  return (
    <Container
      id="perfect choice"
      fluid
      className="!bg-[#2A2C38] xs:!pt-10 pt-16"
      ref={ref}
    >
      <Container>
        <Col>
          <Row className="!flex !items-center !justify-center">
            <h2 className="xs:!text-sm text-3xl font-bold mb-6 text-white !max-h-[39px]">
              The{" "}
              <img
                src={logo}
                className="xs:!w-[120px] xs:!mx-2 !inline !pb-2"
                alt="Brainkets"
              />{" "}
              Way
            </h2>
            <h3 className="!text-[24px] !font-bold !text-white !w-[363.93px] !relative">
              Your Perfect Choice
              <img
                className="!mt-3 !absolute !left-[50%] transform -translate-x-1/2 -translate-y-1/2"
                src={ul}
                alt="design"
              />
            </h3>
          </Row>
          <Row className="!grid !place-items-center xs:!grid-cols-1 sm:!grid-cols-2 md:!grid-cols-3 lg:!grid-cols-3 xl:!grid-cols-3 xxl:!grid-cols-4 !grid-cols-4 !gap-3 !mt-10">
            {props.content.map((c) => (
              <ChoiceCard
                icon={c.icon}
                title={c.title}
                description={c.description}
                key={c.id}
              />
            ))}
          </Row>
        </Col>
      </Container>
    </Container>
  );
};

export default PerfectChoice;
