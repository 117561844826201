import "./PortfolioBox.css";
import { useState } from "react";
import { Skeleton } from "@mui/material";

const PortfolioBox = ({
  className,
  onHover,
  onLeave,
  image,
  id,
  hoveredImage,
  projectDescription,
  projectName,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div
      className={`${className} !relative !rounded-[20px] !w-[300px] !h-[300px]`}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <img
        src={image}
        className={`${
          imageLoaded
            ? "!object-cover !w-full !h-full !rounded-[20px]"
            : "!w-0 !h-0"
        }`}
        alt={projectName}
        loading="lazy"
        onLoad={handleImageLoad}
      />
      {!imageLoaded && (
        <div className="!h-full">
          <Skeleton
            variant="rectangular"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              height: "100%",
              width: "100%",
              borderRadius: "20px",
            }}
          />
        </div>
      )}
      <>
        <div
          className={
            hoveredImage === id
              ? "visible bottom-to-top !absolute !bottom-0 !rounded-b-[20px] !h-[70px] !left-0 !right-0 !bg-white"
              : "visible bottom-to-top !absolute !bottom-0 !rounded-b-[20px] !h-[70px] !left-0 !right-0 !bg-white lg:!hidden xl:!hidden xxl:!hidden"
          }
        >
          <h4 className="!text-[#FDCF00] !capitalize !text-[15px] !text-left !font-bold !pl-2 !pt-1">
            {projectName}
          </h4>
          <h3 className="!text-black !uppercase !text-[15px] !text-left !font-bold !pl-2">
            {projectDescription}
          </h3>
        </div>
      </>
    </div>
  );
};

export default PortfolioBox;
