import classes from "./BlogsDetail.module.css";
import "./Blogs.css";
import { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import ul from "../../../images/ul.svg";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import BlogsCard from "../../../ui/cards/BlogsCard";
import qbg from "../../../images/blogsDetail/quoteBg.png";
import { Skeleton } from "@mui/material";

const BlogsDetail = () => {
  const { title } = useParams();
  const url = useSelector((state) => state.api.url);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getBlogByTitle = async (title) => {
    setIsLoading(true);
    var formData = new FormData();
    formData.append("slug", title);
    await axios.post(`${url}/get-blog-by-title`, formData).then((response) => {
      setData(JSON.parse(response.data));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getBlogByTitle(title);
  }, [title]);

  return (
    <Container fluid className="bg-[#2A2C38]">
      <Col>
        <Row className="xs:!h-[35vh] !h-[55vh] relative">
          <Card className="!rounded-none !bg-transparent !max-h-full !p-0">
            <Card.Img
              src={data.main_content?.main_image}
              className="!min-h-full !max-h-full !object-cover"
            />
            <Card.ImgOverlay
              className="!max-h-full"
              style={{
                background:
                  "linear-gradient(to right,rgba(16, 16, 16, 1),rgba(8, 8, 8, 0.9),rgba(0, 0, 0, 0.7)",
              }}
            >
              <Card.Body
                className="!absolute !top-1/2 !left-1/2 !transform !-translate-x-1/2 !-translate-y-1/2
              !text-[#FDCF00] !w-[80%] !font-bold xs:!leading-10 !leading-[70px]
              xs:!text-[20px] sm:!text-[30px] md:!text-[40px] !text-[50px]"
              >
                {data.main_content?.title}
              </Card.Body>
            </Card.ImgOverlay>
          </Card>
        </Row>
        <Row className="xs:!px-0 sm:!px-0 md:!px-10 !px-16 xs:!mt-4 !mt-14 !w-[100%] !m-0">
          {!isLoading && data.main_content?.description && (
            <div
              className={classes.ckDescription}
              dangerouslySetInnerHTML={{
                __html: data.main_content?.description,
              }}
            />
          )}
          {isLoading && (
            <div className="!flex !flex-col">
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  borderRadius: "20px",
                }}
                height={300}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "2rem",
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  width: "20rem",
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "4rem",
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  width: "13rem",
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "2rem",
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  width: "14rem",
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "2rem",
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  width: "11rem",
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "2rem",
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  width: "10rem",
                }}
              />
            </div>
          )}
        </Row>
        {data.main_content?.quote && (
          <Row className="xs:!mt-3 !mt-10 xs:!h-[100px] !h-[300px]">
            <div
              className="!text-center !relative !h-full"
              style={{
                backgroundImage: `url(${qbg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <p
                className="!absolute !top-1/2 !left-1/2 !transform !-translate-x-1/2 !-translate-y-1/2
             !text-[#FDCF00] xs:!text-[14px] sm:!text-[20px] !text-[33px] !font-semibold"
              >
                {`“${data.main_content?.quote}”`}
              </p>
            </div>
          </Row>
        )}
        <Row className="xs:!mt-3 !mt-10 xs:!px-0">
          <div className="!text-left !my-5 !ml-10">
            <p className="!text-white !text-[36px] !w-max !font-bold !leading-[40px] !relative">
              Related articles
              <img
                className="!mt-3 !absolute !left-[18%] transform -translate-x-1/2 -translate-y-1/2"
                src={ul}
                alt="design"
              />
            </p>
          </div>
          {!isLoading && (
            <div className="!flex !items-center !justify-evenly !flex-wrap">
              {data.related_blogs?.map((b) => (
                <div
                  className="!flex !justify-center !items-center xs:!mb-3 sm:!mb-3"
                  key={b.id}
                >
                  <BlogsCard
                    src={b.main_image}
                    title={b.cat}
                    slug={b.slug}
                    question={b.title}
                    description={b.brief_description}
                    answer={b.description}
                  />
                </div>
              ))}
            </div>
          )}
          {isLoading && (
            <Skeleton
              variant="rectangular"
              animation="wave"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.63)",
                borderRadius: "20px",
                margin: "auto",
              }}
              height={530}
              width={280}
            />
          )}
        </Row>
      </Col>
    </Container>
  );
};

export default BlogsDetail;
