import "./Technologies.css";
import { Container, Row } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import { FreeMode, Pagination, Autoplay } from "swiper";
const Technologies = (props) => {
  return (
    <Container fluid className="tc !mt-14 !py-10 !bg-[#1F1F1F]">
      <Row>
        <p className="!uppercase !text-center !text-[#fdcf00] xs:!text-[22px] !text-[36px] !leading-[19.2px] !font-semibold">
          Technologies
        </p>
      </Row>
      <Swiper
        slidesPerView={5}
        slidesPerGroup={5}
        spaceBetween={0}
        speed={5000}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          stopOnLastSlide: false,
        }}
        loop={true}
        freeMode={true}
        grabCursor={true}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        modules={[Autoplay, Pagination, FreeMode]}
        className="tc xs:!pb-14 !pb-20 xs:!mt-3 !mt-7"
      >
        {props.content?.map((t) => (
          <SwiperSlide className="!grid !place-items-center" key={t.id}>
            <img
              src={t.main_image}
              alt={t.name}
              className="xs:max-h-20 xs:h-20 max-h-40 h-40 w-40 object-contain"
              loading="lazy"
            />
            <p className="!text-white !uppercase">{t.name}</p>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default Technologies;
