import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
//
import { stylesActions } from "../../../../store/Styles-slice";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
//
import OurServicesBox from "../../../ui/boxes/OurServicesBox";
import ServicesCard from "../../../ui/cards/ServicesCard";
import LearMoreButton from "../../../ui/buttons/LearnMoreButton";
import ul from "../../../images/ul.svg";
//
import AOS from "aos";
import "aos/dist/aos.css";

const OurServices = ({ content }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  const dispatch = useDispatch();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(stylesActions.changeSectionName("Services"));
    }
  }, [inView]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <Container
      ref={ref}
      className="!bg-[#1F1F1F] xs:!pb-2 pt-10 !relative"
      id="services"
      fluid
      data-aos="fade-up"
      data-aos-once="true"
    >
      <Container className="!pt-14 xs:!p-0 !p-5" fluid>
        <Col>
          <Row className="!flex !items-center !justify-center">
            <p className="!text-[18px] !text-[#FDCF00] !uppercase">Services</p>
            <h3 className="!text-[36px] !font-bold !text-white !relative">
              Our Services
              <img
                className="!mt-3 !absolute !left-[50%] transform -translate-x-1/2 -translate-y-1/2"
                src={ul}
                alt="line"
              />
            </h3>
          </Row>
          <Row className="!mt-5 xl:!mt-7 xxl:!mt-10 xs:!overflow-visible !overflow-hidden">
            <div className="!m-auto !max-w-[1676px] !grid xs:!grid-cols-1 xs:!gap-10 sm:!grid-cols-2 md:!grid-cols-2 lg:!grid-cols-3 xl:!grid-cols-5 xxl:!grid-cols-5 !gap-0 !grid-cols-5 !place-items-center">
              {content?.slice(0, 10)?.map((s) => (
                <div key={s.id} className="!w-full">
                  {!isMobile ? (
                    <Link to={`/services/${s.slug}`}>
                      <OurServicesBox img={s.main_image} title={s.slug} />
                    </Link>
                  ) : (
                    <ServicesCard
                      to={`/services/${s.slug}`}
                      src={s.main_image}
                      title={s.title}
                      brief_description={s.brief_description}
                    />
                  )}
                </div>
              ))}
            </div>
          </Row>
          <LearMoreButton to="/services" className="!m-auto xs:!mt-8 !mt-10">
            View All
          </LearMoreButton>
        </Col>
      </Container>
    </Container>
  );
};

export default OurServices;
