import "../../navbar/NavBar.css";
import "./Portfolio.css";
import { Container, Row } from "react-bootstrap";
import PortfolioBox from "../../../ui/boxes/PortfolioBox";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
//
import ul from "../../../images/ul.svg";
//
import { useMediaQuery } from "react-responsive";
import PortfolioSwiper from "./PortfolioSwiper";
import { stylesActions } from "../../../../store/Styles-slice";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import LearnMoreButton from "../../../ui/buttons/LearnMoreButton";

const Portfolio = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const [hoveredImage, setHoveredImage] = useState(null);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [imageToOpen, setImageToOpen] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [projects, setProjects] = useState([]);
  const categoryHandler = (id) => {
    if (id === 0) {
      setProjects(props.content2);
    } else {
      const selectedProjects = props?.content2.filter((p) =>
        p.project_categories?.includes(id)
      );
      setProjects(selectedProjects);
    }
    setSelectedCategory(id);
  };

  const handleClose = () => {
    setOpenBackDrop(false);
    setImageToOpen(null);
  };

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(stylesActions.changeSectionName("Our Projects"));
    }
  }, [inView]);

  useEffect(() => {
    setProjects(props.content2);
  }, [props.content2]);

  return (
    <Container
      ref={ref}
      id="projects"
      className="po !mt-14 !bg-black p-10 !rounded-[30px] !overflow-hidden"
    >
      <Row className="!flex !items-center !justify-center">
        <p className="!text-[18px] !text-[#FDCF00] !uppercase">Portfolio</p>
        <h3 className="!text-[36px] !font-bold !text-white !relative">
          Our Portfolio
          <img
            className="!mt-3 !absolute !left-[50%] transform -translate-x-1/2 -translate-y-1/2"
            src={ul}
            alt="design"
          />
        </h3>
      </Row>
      <Row className="xs:!px-0 !px-0 sm:!px-0 lg:!px-44 xl:!px-44 xxl:!px-44 !pt-10">
        <Swiper
          slidesPerView={3}
          breakpoints={{
            460: {
              slidesPerView: 3,
            },
            640: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 200,
            },
            1400: {
              slidesPerView: 4,
            },
          }}
          className="!ma-uto !flex !justify-center !items-center"
        >
          <SwiperSlide
            tag="p"
            className={`pos pt-3 ${
              selectedCategory === 0 ? "!text-[#FDCF00]" : "!text-white"
            } xl:!whitespace-nowrap xxl:!whitespace-nowrap xs:text-[13px] xs:!w-max xs:!p-3 xs:!text-left sm:!text-[14px] text-[20px]`}
            onClick={() => categoryHandler(0)}
          >
            All
          </SwiperSlide>
          {props.content1?.map((p) => (
            <SwiperSlide
              tag="p"
              className={`pos pt-3 ${
                selectedCategory === p.id ? "!text-[#FDCF00]" : "!text-white"
              } xl:!whitespace-nowrap xxl:!whitespace-nowrap xs:text-[13px] xs:!w-max xs:!p-3 xs:!text-left sm:!text-[14px] text-[20px]`}
              key={p.id}
              onClick={() => categoryHandler(p.id)}
            >
              {p.category_name}
            </SwiperSlide>
          ))}
        </Swiper>
      </Row>
      {!isMobile && (
        <Row className="!mt-5 !min-h-[343px] relative">
          {projects.length > 0 && (
            <div
              className="!grid xs:!grid-cols-1 !grid-cols-3 sm:!grid-cols-1
          md:!grid-cols-2 lg:!grid-cols-3 xl:!grid-cols-3 xxl:!grid-cols-4 !gap-5 !place-items-center"
            >
              {projects.slice(0, 8).map((p, i) => (
                <Link key={i} to={`/portfolio/${p.slug}`}>
                  <PortfolioBox
                    image={p.main_image}
                    fileName={p.file_name}
                    path={p.path}
                    projectName={p.title}
                    projectDescription={p.brief_description}
                    onHover={() => {
                      setHoveredImage(p.id);
                    }}
                    onLeave={() => {
                      setHoveredImage(null);
                    }}
                    onImageClick={() => {
                      setOpenBackDrop(true);
                      setImageToOpen(i + 1);
                    }}
                    key={p.id}
                    id={p.id}
                    hoveredImage={hoveredImage}
                    className={`portfolio-box ${
                      selectedCategory !== 0 &&
                      !p.project_categories.includes(selectedCategory)
                        ? "hidden"
                        : "visible"
                    }`}
                  />
                </Link>
              ))}
            </div>
          )}
          {projects.length === 0 && (
            <h1 className="!text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              No projects found
            </h1>
          )}
        </Row>
      )}
      {isMobile && (
        <Row className="!mt-5 !min-h-[343px] relative">
          {projects.length > 0 && (
            <Swiper
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={true}
              modules={[Autoplay, Pagination]}
              className="swiper !relative !h-full"
              breakpoints={{
                200: {
                  slidesPerView: 1,
                  spaceBetween: 60,
                },
                460: {
                  slidesPerView: 1,
                  spaceBetween: 70,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 90,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 3,
                },
              }}
            >
              {projects.slice(0, 8).map((p, i) => (
                <SwiperSlide
                  key={i}
                  className="!flex !items-center !justify-center"
                >
                  <Link to={`/portfolio/${p.slug}`}>
                    <PortfolioBox
                      image={p.main_image}
                      projectName={p.title}
                      projectDescription={p.brief_description}
                      onHover={() => {
                        setHoveredImage(p.id);
                      }}
                      onLeave={() => {
                        setHoveredImage(null);
                      }}
                      onImageClick={() => {
                        setOpenBackDrop(true);
                        setImageToOpen(i + 1);
                      }}
                      key={p.id}
                      id={p.id}
                      hoveredImage={hoveredImage}
                      className={`portfolio-box ${
                        selectedCategory !== 0 &&
                        !p.project_categories.includes(selectedCategory)
                          ? "hidden"
                          : "visible"
                      }`}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          {projects.length === 0 && (
            <h1 className="!text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              No projects found
            </h1>
          )}
        </Row>
      )}
      <LearnMoreButton to="/portfolio" className="!m-auto !mt-10">
        View All
      </LearnMoreButton>
      {imageToOpen && openBackDrop && (
        <PortfolioSwiper
          openBackDrop={openBackDrop}
          handleClose={handleClose}
          imageToOpen={imageToOpen}
          portfolioData={projects}
        />
      )}
    </Container>
  );
};

export default Portfolio;
