import "./BlackButton.css";
import { Link } from "react-router-dom";
const BlackButton = (props) => {
  return (
    <Link to={props.to} className={`custom-btn3 btn-16 ${props.className}`}>
      {props.children}
    </Link>
  );
};

export default BlackButton;
