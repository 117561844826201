import { Container, Row, Col } from "react-bootstrap";
import ContactUsForm from "../../ui/forms/ContactUsForm";
import ul from "../../images/ul.svg";
import bg from "../../images/home/contact-us/bg.jpg";

const ContactUs = ({ content }) => {
  return (
    <Container
      fluid
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container className="xs:!pt-10 !pt-32 md:!max-w-full !pb-10">
        <Row className="!flex !items-center !justify-center">
          {content && (
            <p className="!text-[18px] !text-[#FDCF00] !uppercase">
              {content[0]?.title}
            </p>
          )}
          {content && (
            <h3 className="!text-[36px] !font-bold !text-white !relative">
              {content[0]?.subtitle}
              <img
                className="!mt-3 !absolute !left-[50%] transform -translate-x-1/2 -translate-y-1/2"
                src={ul}
                alt="design"
              />
            </h3>
          )}
        </Row>
        <Row className="!mt-10">
          <Col xs={12} sm={12} md={7} lg={7} xl={7} xxl={7}>
            <ContactUsForm />
          </Col>
          {content && (
            <Col
              className="!relative xs:!mt-5 sm:!mt-5"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
            >
              <Row>
                <iframe
                  title="Google Map"
                  src={content[0]?.map_link}
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen={true}
                  loading="lazy"
                ></iframe>
              </Row>
              <Row className="!w-fit">
                <a
                  href="https://www.google.com/maps/dir//Brainkets+Main+street+Tyre/@33.2375015,35.2508562,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x151e876d2e8bb3b7:0x122f4250fa833e2c"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="!text-white !text-lg !text-left !mt-2"
                >
                  Get Directions
                </a>
              </Row>
              <Row className="xs:!hidden sm:!hidden !text-left !absolute !bottom-14 !left-2">
                <div className="!flex-col">
                  <p>
                    <a
                      className="!text-white text-[18px] !font-semibold !leading-[16px]"
                      href={`tel:${content[0]?.phone_number}`}
                    >
                      <i
                        className="fa-solid fa-mobile pr-3"
                        style={{ color: "#fdcf00" }}
                      ></i>{" "}
                      {content[0]?.phone_number}
                    </a>
                  </p>
                  <p className="!text-white text-[18px] !font-semibold !leading-[16px]">
                    <i
                      className="fa-sharp fa-solid fa-location-dot pr-3"
                      style={{ color: "#fdcf00" }}
                    ></i>{" "}
                    {content[0]?.address}
                  </p>
                  <p className="!text-white text-[18px] !font-semibold !leading-[16px]">
                    <i
                      className="fa-solid fa-envelope pr-2"
                      style={{ color: "#fdcf00" }}
                    ></i>{" "}
                    <a
                      className="!text-white"
                      href={`mailto:${content[0]?.email}`}
                    >
                      {content[0]?.email}
                    </a>
                  </p>
                </div>
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    </Container>
  );
};

export default ContactUs;
