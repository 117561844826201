import { Row, Col, Container } from "react-bootstrap";
import { useEffect } from "react";
import BlackButton from "../../../ui/buttons/BlackButton";
import { stylesActions } from "../../../../store/Styles-slice";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
//
import AOS from "aos";
import "aos/dist/aos.css";

const GetInTouch = () => {
  const dispatch = useDispatch();
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(stylesActions.changeSectionName("Get In Touch"));
    }
  }, [inView]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <Container fluid ref={ref}>
      <Row
        className="!h-[200px] !bg-[#FDCF00] !text-black !grid !place-items-center !relative"
        data-aos="flip-left"
        data-aos-once="true"
        id="inTouch"
      >
        <Col xs={12} sm={12} md={6} lg={6}>
          <h2 className="xs:!text-[30px] !text-[40px] !font-bold xs:!leading-[50px] sm:!leading-[40px] !leading-[60px]">
            Do you need a new project?
          </h2>
        </Col>
        <Col>
          <BlackButton to="contact-us">Get In Touch</BlackButton>
        </Col>
      </Row>
    </Container>
  );
};

export default GetInTouch;
