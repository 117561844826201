import { Container, Row, Col } from "react-bootstrap";
import classes from "./AboutUsForm.module.css";
import arrow from "../../images/aboutUs/arrow.svg";
//
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";

const AboutUsForm = (props) => {
  return (
    <Container
      fluid
      className="!pb-10 xs:!pt-16 sm:!pt-32 xs:!px-7 sm:!px-14 md:!px-6 !pt-32"
    >
      <Col>
        <Row className="!relative xxl:!h-[80vh]">
          {props.content && (
            <div
              className={`${classes.ckTitle} !text-white !font-bold !absolute !left-[5%] xs:!top-1/3 sm:!top-1/2 md:!top-1/3 lg:!top-1/3 xl:!top-[10%] xxl:!top-24 xs:!w-[90%] md:!w-[80%] !w-[758px] !text-left`}
              dangerouslySetInnerHTML={{ __html: props.content[0]?.title }}
            />
          )}
          <Col
            xs={0}
            sm={0}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            className="!max-h-full"
          ></Col>
          <Col
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            xxl={9}
            className="!max-h-full"
          >
            {props.content && (
              <LazyLoad className="!h-full">
                <ProgressiveImage
                  src={props.content[0]?.main_image}
                  placeholder={`${props.content[0]?.thumb}`}
                >
                  {(src) => (
                    <img
                      src={src}
                      className="xs:!min-h-[227px] !w-full !h-full xxl:!min-h-[525px] xxl:!h-[95%] !object-cover"
                      alt="About Us"
                    />
                  )}
                </ProgressiveImage>
              </LazyLoad>
            )}
          </Col>
        </Row>
        <Row className="xs:!px-0 sm:!px-2 md:!px-5 !px-20 xs:!mt-8 sm:!mt-10 md:!mt-14 lg:!mt-16 xl:!mt-20 xxl:!mt-10">
          <Col
            xs={0}
            sm={0}
            md={3}
            lg={3}
            xl={3}
            xxl={3}
            className="!flex !flex-col !justify-end"
          >
            <div className="!flex !flex-col !text-[20px] !text-white">
              <div className="!border-b-2">
                <p className="!text-left !mb-1">Follow Us</p>
              </div>
              {props.socialMedia?.map((s, i) => (
                <div
                  className="!border-b-2 !mt-4 !pb-2 !px-2 !flex !items-center !justify-between"
                  key={i}
                >
                  <div className="!flex !items-center !justify-start">
                    <i className={s.icon} style={{ color: "#fdcf00" }} />
                    <a
                      href={s.link}
                      className="!text-white"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="!mb-0 !ml-2">{s.name}</p>
                    </a>
                  </div>
                  <div>
                    <a href={s.link} target="_blank" rel="noopener noreferrer">
                      <img
                        src={arrow}
                        alt="design"
                        className="hover:!transform hover:!-translate-y-2 !transition-transform"
                      />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            xxl={9}
            className="xs:!mt-8 sm:!mt-10"
          >
            {props.content && (
              <div
                className={classes.ckDescription}
                dangerouslySetInnerHTML={{
                  __html: props.content[0].description,
                }}
              ></div>
            )}
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default AboutUsForm;
