import { Container, Row } from "react-bootstrap";
import OurServicesBox from "../../ui/boxes/OurServicesBox";
import ul from "../../images/ul.svg";
import { Link } from "react-router-dom";

const ServicesForm = (props) => {
  return (
    <Container className="!pb-10 xs:!mt-10 !mt-32" fluid>
      <Row className="!flex !items-center !justify-center">
        <p className="!text-[18px] !text-[#FDCF00] !uppercase">Services</p>
        <h3 className="!text-[36px] !font-bold !text-white !relative">
          Our Services
          <img
            className="!mt-3 !absolute !left-[50%] transform -translate-x-1/2 -translate-y-1/2"
            src={ul}
            alt="design"
          />
        </h3>
      </Row>
      <Row className="!mt-5 xl:!mt-7 xxl:!mt-10">
        <div className="!m-auto !max-w-[1676px] !grid xs:!grid-cols-1 xs:!gap-4 sm:!grid-cols-2 md:!grid-cols-2 lg:!grid-cols-3 xl:!grid-cols-5 xxl:!grid-cols-5 !grid-cols-4 !gap-0 !place-items-center">
          {props.content?.map((s) => (
            <Link to={`/services/${s.slug}`} key={s.id}>
              <OurServicesBox img={s.main_image} title={s.slug} />
            </Link>
          ))}
        </div>
      </Row>
    </Container>
  );
};

export default ServicesForm;
