import { useState } from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";

const ServicesCard = ({ to, src, title, brief_description }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  
  return (
    <div className="!w-full flex items-center justify-start my-2 border-b-[1px] pb-4">
      <Link to={to}>
        <img
          src={src}
          className={`${
            !imageLoaded
              ? "!w-0 !h-0"
              : "!min-w-[110px] !min-h-[90px] !max-w-[110px] !max-h-[90px] !rounded-[10px] !object-contain hover:!scale-105"
          }`}
          alt={title}
          onLoad={handleImageLoad}
        />
      </Link>
      {!imageLoaded && (
        <Skeleton
          variant="rectangular"
          height={100}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.63)",
            width: "7rem",
            minWidth: "7rem",
          }}
        />
      )}
      <div className="flex flex-col pl-4 text-left">
        <Link to={to}>
          <p className="text-[#FDCF00] text-[20px] leading-5 mb-2 hover:text-[#f1d177]">
            {title}
          </p>
        </Link>
        <p className="!pt-3 text-white text-[14px] leading-4 line-clamp-3">
          {brief_description}
        </p>
      </div>
    </div>
  );
};

export default ServicesCard;
