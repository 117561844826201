import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import ImageBackdrop from "../../../ui/images/ImageBackdrop";
const PortfolioSwiper = (props) => {
  return (
    <ImageBackdrop open={props.openBackDrop} handleClose={props.handleClose}>
      <Swiper
        loop={true}
        pagination={true}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="!grid !place-items-center"
        slidesPerView={1}
        initialSlide={props.imageToOpen - 1}
        grabCursor
      >
        {props.portfolioData.map((p, i) => (
          <SwiperSlide
            className="!grid !place-items-center !select-none"
            key={i + 1}
          >
            <img
              src={p.main_image}
              className="xs:!h-[300px] xs:!w-[300px] md:!h-[80%] md:!w-[80%] !h-[50%] !w-[50%] !object-contain"
              alt="..."
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </ImageBackdrop>
  );
};

export default PortfolioSwiper;
