import Card from "react-bootstrap/Card";
import LazyLoad from "react-lazy-load";

const PortfolioBigBox = ({ className, image, title, catName }) => {
  return (
    <div
      className={`${className} !m-auto !overflow-hidden xs:!w-[340px] sm:!w-[500px] md:!w-[350px] lg:!w-[450px] xl:!w-[580px] xxl:!w-[650px] !w-[825px] xs:!h-[300px] !h-[450px] !rounded-[20px]`}
    >
      <Card className="pbox !overflow-hidden !h-full !w-full !bg-transparent !rounded-[20px]">
        <LazyLoad className="!h-full">
          <Card.Img
            src={image}
            className="!w-full !h-full !max-h-full !max-w-full !object-cover !rounded-[20px]"
          />
        </LazyLoad>
        <div className="!pr-7 !absolute !bottom-0 !left-0 !right-0 !bg-white !rounded-b-[20px] !h-[80px] !pt-1 !pl-1 xxl:!pl-3">
          <p className="!font-bold !text-left xs:!text-[14px] md:!text-[17px] lg:!text-[25px] !text-[25px] xxl:!mb-0 !uppercase">
            {title}
          </p>
          <p className="!font-bold !text-left xs:!text-[13px] md:!text-[15px] lg:!text-[18px] !text-[18px] !uppercase">
            {catName}
          </p>
        </div>
      </Card>
    </div>
  );
};

export default PortfolioBigBox;
