import { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import classes from "../../layout/home/blogs/BlogsDetail.module.css";
import LearMoreButton from "../../ui/buttons/LearnMoreButton";
//
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
//
import { Skeleton } from "@mui/material";

const SingleProduct = () => {
  const { title } = useParams();
  const url = useSelector((state) => state.api.url);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getProductByTitle = async (title) => {
    setIsLoading(true);
    var formData = new FormData();
    formData.append("slug", title);
    await axios
      .post(`${url}/get-product-by-title`, formData)
      .then((response) => {
        setData(JSON.parse(response.data));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProductByTitle(title);
  }, [title]);

  return (
    <Container className="!pb-10">
      <Row className="xs:!h-[40vh] !h-[60vh] xs:!mt-[5%] sm:!mt-[24%] md:!mt-[20%] lg:!mt-[15%] !mt-[10%]">
        {!isLoading && (
          <img
            src={data?.main_content?.main_image}
            className="!w-full !max-h-full !object-contain"
            alt="project"
          />
        )}
        {isLoading && (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.63)",
              borderRadius: "20px",
              margin: "auto",
            }}
            className="!h-full"
            width={"50%"}
          />
        )}
      </Row>
      <Row className="xs:!pl-2 !pl-14 !mt-4">
        <div className="xs:!text-xs !flex !items-center !justify-start !text-lg">
          <Link to="/products" className="!text-white !mr-2">
            Products
          </Link>
          <span className="!text-white">/</span>
          <p className="!text-[#FDCF00] !mb-0 !ml-2 !uppercase">{title}</p>
        </div>
      </Row>
      <Row className="xs:!px-0 sm:!px-0 md:!px-10 !px-16 xs:!mt-4 !mt-7 !w-full !m-0">
        {!isLoading && data.main_content?.description && (
          <div
            className={classes.ckDescription}
            dangerouslySetInnerHTML={{
              __html: data.main_content?.description,
            }}
          />
        )}
        {isLoading && (
          <div className="!flex !flex-col">
            <Skeleton
              variant="rectangular"
              animation="wave"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.63)",
                borderRadius: "20px",
              }}
              height={300}
            />
          </div>
        )}
      </Row>
      <Row>
        <LearMoreButton to="/contact-us" className="!mt-5 !w-[170px] !m-auto">
          Contact Us
        </LearMoreButton>
      </Row>
    </Container>
  );
};

export default SingleProduct;
