import { createSlice } from "@reduxjs/toolkit";

const DataSlice = createSlice({
  name: "data",
  initialState: {
    footerServices: [],
    footerContactData: [],
  },
  reducers: {
    setFooterServices(state, action) {
      state.footerServices = action.payload;
    },
    setFooterContactData(state, action) {
      state.footerContactData = action.payload;
    },
  },
});

export const dataActions = DataSlice.actions;
export default DataSlice;
