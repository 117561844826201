import React, { useRef } from "react";
import "./ScrollToTopButton.css";
// import img from "../images/upBtn.png";
const ScrollToTopButton = () => {
  const scrollToTopButton = useRef();
  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 0) {
      scrollToTopButton.current?.classList.remove("hide");
      scrollToTopButton.current?.classList.add("show");
    } else {
      scrollToTopButton.current?.classList.add("hide");
    }
  });

  // const scrollTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }
  return (
    <div>
      <button id="scroll-to-top" ref={scrollToTopButton}>
        {/* <img src={img} alt="..." className="!p-3" /> */}
        <a
          href="https://wa.me/+96176002661"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp !font-bold !text-[24px] !text-[#FDCF00]"></i>
        </a>
      </button>
    </div>
  );
};

export default ScrollToTopButton;
