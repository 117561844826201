import { Col, Container, Row } from "react-bootstrap";
import ul from "../../images/ul.svg";
import { Link } from "react-router-dom";
import BlogsCard from "../../ui/cards/BlogsCard";

const BlogsForm = (props) => {
  return (
    <Container className="xs:!mt-10 !mt-32" fluid>
      <Col>
        <Row className="!flex !items-center !justify-center">
          <p className="!text-[18px] !text-[#FDCF00] !uppercase">Blogs</p>
          <h3 className="!text-[36px] !font-bold !text-white !relative">
            Our Blogs
            <img
              className="!mt-3 !absolute !left-[50%] transform -translate-x-1/2 -translate-y-1/2"
              src={ul}
              alt="design"
            />
          </h3>
        </Row>
        <Row className="!mt-5 xl:!mt-7 xxl:!mt-10">
          <div className="!grid xs:!grid-cols-1 xs:!gap-4 sm:!grid-cols-2 md:!grid-cols-2 lg:!grid-cols-3 xl:!grid-cols-4 xxl:!grid-cols-4 !grid-cols-4 !gap-5 !place-items-center">
            {props.content?.map((s) => (
              <Link to={`/blogs/${s.slug}`} key={s.id}>
                <BlogsCard
                  src={s.main_image}
                  title={s.cat}
                  question={s.title}
                  slug={s.slug}
                  description={s.brief_description}
                  answer={s.description}
                />
              </Link>
            ))}
          </div>
        </Row>
      </Col>
    </Container>
  );
};

export default BlogsForm;
