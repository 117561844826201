import { Backdrop } from "@mui/material";
import { useEffect } from "react";
const ImageBackdrop = (props) => {
  const handleBackdropClick = (event) => {
    // Prevent backdrop click when interacting with the navigation buttons
    if (
      event.target.classList.contains("swiper-button-next") ||
      event.target.classList.contains("swiper-button-prev")
    ) {
      return;
    }

    props.handleClose();
  };
  useEffect(() => {
    if (props.open) {
      // Disable scrolling when the backdrop is opened
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the backdrop is closed
      document.body.style.overflow = "auto";
    }

    return () => {
      // Clean up the effect by re-enabling scrolling on component unmount
      document.body.style.overflow = "auto";
    };
  }, [props.open]);
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.open}
      onClick={handleBackdropClick}
    >
      {props.children}
    </Backdrop>
  );
};

export default ImageBackdrop;
