import "./OurTeam.css";
import { useEffect, useState } from "react";
//
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//
import OurTeamCard from "../../../ui/cards/OurTeamCard";
import LearMoreButtonClick from "../../../ui/buttons/LearnButtonClick";
//
import ul from "../../../images/ul.svg";
//
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
//
import { useMediaQuery } from "react-responsive";
import { stylesActions } from "../../../../store/Styles-slice";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";

const OurTeam = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      dispatch(stylesActions.changeSectionName("Talents"));
    }
  }, [inView]);

  const [displayedItems, setDisplayedItems] = useState(6);

  const handleLoadMore = () => {
    if (displayedItems < props.content.length) {
      setDisplayedItems(props.content.length);
    } else {
      setDisplayedItems(6);
      window.scrollTo({
        top: window.scrollY - 1000,
        behavior: "smooth",
      });
    }
  };

  return (
    <Container ref={ref} id="our-team" className="!relative pt-12">
      <Col>
        <Row className="!flex !items-center !justify-center">
          <p className="!text-[18px] !text-[#FDCF00] !uppercase">Team</p>
          <h3 className="xs:!text-[25px] !text-[36px] !font-bold !text-white !relative">
            Meet Our Creative Team
            <img
              className="!mt-3 !absolute !left-[50%] transform -translate-x-1/2 -translate-y-1/2"
              src={ul}
              alt="design"
            />
          </h3>
        </Row>
        <Row>
          {!isMobile && (
            <>
              <div
                className={`!mt-6 !grid xs:!grid-cols-1 sm:!grid-cols-2 md:!grid-cols-2 !grid-cols-3 !place-items-center !gap-3 xs:!ml-3`}
                id="tAnim"
              >
                {props.content?.slice(0, displayedItems).map((t, i) => (
                  <OurTeamCard
                    img={t.main_image}
                    key={t.id}
                    name={t.name}
                    role={t.position}
                    linkedIn={t.linkedin}
                    className={i >= 6 ? "fade-in" : ""}
                  />
                ))}
              </div>
              {props.content?.length > 6 && (
                <LearMoreButtonClick
                  onClick={handleLoadMore}
                  className="!w-44 !mx-auto !my-10"
                >
                  {displayedItems < props.content.length
                    ? "Load More"
                    : "Show Less"}
                </LearMoreButtonClick>
              )}
            </>
          )}
          {isMobile && (
            <div>
              <Swiper
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="or swiper !relative !h-full"
                breakpoints={{
                  200: {
                    slidesPerView: 1,
                    spaceBetween: 60,
                  },
                  460: {
                    slidesPerView: 2,
                    spaceBetween: 60,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 90,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                  1400: {
                    slidesPerView: 3,
                  },
                }}
              >
                {props.content?.map((t) => (
                  <SwiperSlide
                    key={t.id}
                    className="!flex !items-center !justify-center !pl-8"
                  >
                    <OurTeamCard
                      img={t.main_image}
                      key={t.id}
                      name={t.name}
                      role={t.position}
                      linkedIn={t.linkedin}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </Row>
      </Col>
    </Container>
  );
};

export default OurTeam;
