import "../home/portfolio/Portfolio.css";
import { Container, Col, Row, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
//
import PortfolioBigBox from "../../ui/boxes/PortfolioBigBox";
//
import ul from "../../images/ul.svg";
//
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import LearnMoreButtonClick from "../../ui/buttons/LearnButtonClick";
import { Skeleton } from "@mui/material";

const PortfolioForm = (props) => {
  const url = useSelector((state) => state.api.url);

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [projects, setProjects] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState();
  const [showButton, setShowButton] = useState(true);

  const categoryHandler = (id) => {
    setOffset(0);
    setSelectedCategory(id);
  };

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      await axios
        .get(
          `${url}/get-more-projects?offset=${offset}&limit=6&category_id=${selectedCategory}`
        )
        .then((res) => {
          if (res.data) {
            const newProjects = JSON.parse(res.data).projects;
            if (offset === 0) {
              setProjects(newProjects);
            } else {
              setProjects((prevProjects) => [...prevProjects, ...newProjects]);
            }
            if (newProjects.length < 6) {
              setShowButton(false);
            } else {
              setShowButton(true);
            }
          } else {
            console.log("no data");
          }
        });
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProjects();
  }, [offset, selectedCategory]);

  const loadMoreHandler = (e) => {
    e.preventDefault();
    setOffset((o) => o + 6);
  };

  useEffect(() => {
    if (selectedCategory) {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 500);
    }
  }, [selectedCategory]);

  useEffect(() => {
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", "test description");
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute(
        "content",
        "https://chat.openai.com/images/chatgpt-share-og.png"
      );
  }, []);

  return (
    <Container className="!pb-10" fluid>
      <Row className="xs:!h-[35vh] !h-[55vh] relative">
        <Card className="!rounded-none !bg-transparent !max-h-full !p-0">
          <Card.Img
            src={props.content3?.file_path}
            className="!min-h-full !max-h-full !object-cover !mix-blend-overlay"
          />
          <Card.ImgOverlay className="!max-h-full">
            <Card.Body
              className="!absolute !top-1/2 !left-1/2 !transform !-translate-x-1/2 !-translate-y-1/2
                !flex-col !items-center !justify-center !w-full"
            >
              <p
                className="!text-[#FDCF00] !m-0 !font-bold xs:!leading-10 !leading-[70px]
              xs:!text-[20px] sm:!text-[30px] md:!text-[40px] !text-[50px]"
              >
                {props.content3?.subtitle}
              </p>
              <p className="text-white xs:!text-[17px] !text-[31px] !mt-3 !w-full">
                {props.content3?.title}
              </p>
            </Card.Body>
          </Card.ImgOverlay>
        </Card>
      </Row>
      <Col className="!px-8 xs:!px-0 sm:!px-0">
        <Row>
          <p className="!text-center mt-3 xs:!text-[20px] !text-white text-[36px] !relative">
            Our Projects
            <img
              className="!mt-3 !left-1/2 !absolute transform -translate-x-1/2 -translate-y-1/2"
              src={ul}
              alt="design"
            />
          </p>
        </Row>
        <Row className="xs:!px-0 !px-0 sm:!px-0 lg:!px-44 xl:!px-44 xxl:!px-44 xs:!pt-0 !pt-10">
          <Swiper
            slidesPerView={3}
            navigation={window.screen.width > 600 ? true : false}
            modules={[Navigation]}
            breakpoints={{
              460: {
                slidesPerView: 3,
                spaceBetween: 30,
                navigation: false,
              },
              640: {
                slidesPerView: 4,
                spaceBetween: 10,
                navigation: false,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 70,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 100,
              },
              1400: {
                slidesPerView: 5,
                spaceBetween: 0,
              },
            }}
            className="!m-auto !flex !text-center !justify-center !items-center"
          >
            <SwiperSlide
              tag="p"
              className={`pt-3 ${
                selectedCategory === 0 ? "!text-[#FDCF00]" : "!text-white"
              } xl:!whitespace-nowrap xxl:!whitespace-nowrap xs:text-[13px] xs:!w-max xs:!p-3 xs:!text-left sm:!text-[14px] text-[20px]
              hover:!text-[#FDCF00] !cursor-pointer`}
              onClick={() => categoryHandler(0)}
            >
              <span className="pof">All</span>{" "}
            </SwiperSlide>
            {props.content1?.map((p, i) => (
              <SwiperSlide
                key={i}
                tag="p"
                className={`pt-3 ${
                  selectedCategory === p.id ? "!text-[#FDCF00]" : "!text-white"
                } xl:!whitespace-nowrap xxl:!whitespace-nowrap xs:text-[13px] xs:!w-max xs:!p-3 xs:!text-left sm:!text-[14px] text-[20px]
                hover:!text-[#FDCF00] !cursor-pointer`}
                onClick={() => categoryHandler(p.id)}
              >
                <span className="pof">{p.category_name}</span>
              </SwiperSlide>
            ))}
          </Swiper>
        </Row>
        <Row className="!m-auto !max-w-[1400px] !mt-5 relative">
          {!showLoader && projects?.length > 0 && (
            <div className="!mx-auto !grid xs:!grid-cols-1 sm:!grid-cols-1 !grid-cols-2 !gap-3 xl:!gap-6 xxl:!gap-8 !place-items-center">
              {projects.map((p, i) => {
                const categoryIds = p.project_categories?.split(", ");
                const catName = categoryIds
                  ?.map((categoryId) => {
                    const category = props.content1?.find(
                      (cat) => cat.id == categoryId
                    );
                    return category ? category.category_name : "";
                  })
                  .join(", ");

                return (
                  <Link to={`/portfolio/${p.slug}`} key={i}>
                    <PortfolioBigBox
                      title={p.title}
                      image={p.main_image}
                      slug={p.slug}
                      catName={catName}
                      className={i >= 6 ? "fade-in" : ""}
                    />
                  </Link>
                );
              })}
            </div>
          )}
          {(showLoader || isLoading) && (
            <div className="!mt-5 !flex xs:!flex-col !items-center !justify-between xs:!h-[300px] !h-[450px]">
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  borderRadius: "20px",
                  margin: "auto",
                }}
                className="xs:!w-[210px] !m-auto sm:!w-[200px] md:!w-[350px] !w-[40%] xs:!h-[300px] sm:!h-[300px] !h-[450px]"
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.63)",
                  borderRadius: "20px",
                }}
                className="xs:!mt-5 xs:!w-[210px] !m-auto sm:!w-[200px] md:!w-[350px] !w-[40%] xs:!h-[300px] sm:!h-[300px] !h-[450px]"
              />
            </div>
          )}
          {!isLoading && projects?.length === 0 && (
            <h1 className="!text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              No projects found
            </h1>
          )}
        </Row>
        {projects?.length >= 6 && showButton && (
          <LearnMoreButtonClick
            className="!m-auto !mt-10"
            onClick={loadMoreHandler}
          >
            Load More
          </LearnMoreButtonClick>
        )}
      </Col>
    </Container>
  );
};

export default PortfolioForm;
