import "./LearnMoreButton.css";
import { Link } from "react-router-dom";
const LearMoreButton = (props) => {
  return (
    <Link to={props.to} className={`custom-btn2 btn-15 ${props.className}`}>
      {props.children}
    </Link>
  );
};

export default LearMoreButton;
