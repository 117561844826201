import { useEffect } from "react";
import { gsap } from "gsap";
import "./GsapBg.css";
import { useMediaQuery } from "react-responsive";
import { stylesActions } from "../../../../store/Styles-slice";
import { useDispatch } from "react-redux";

const GsapBg = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  useEffect(() => {
    let previousWidth = window.innerWidth;

    const handleResize = () => {
      const currentWidth = window.innerWidth;
      if (currentWidth !== previousWidth) {
        window.location.reload();
      }
    };

    const handleScroll = () => {
      previousWidth = window.innerWidth;
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth > 700) {
      var currentImg = undefined,
        currentImgProps = { x: 0, y: 0 },
        isZooming = false,
        column = -1,
        mouse = { x: 0, y: 0 },
        delayedPlay;

      for (var i = 0; i < props.images.length; i++) {
        if (i % 6 === 0) column++;

        var encodedFilePath = props.images[i].file_path.replace(/\s/g, "%20");
        var b = document.createElement("div");
        var mainBoxes = document.querySelector(".mainBoxes");
        mainBoxes.appendChild(b);
        gsap.set(b, {
          attr: { id: "b" + i, class: "photoBox pb-col" + column },
          backgroundImage: `url(${encodedFilePath})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
          x: [60, 280, 500][column],
          width: 400,
          height: 640,
          borderRadius: 20,
          scale: 0.5,
          zIndex: 1,
        });

        b.tl = gsap
          .timeline({ paused: true, repeat: -1 })
          .fromTo(
            b,
            { y: [-575, 800, 800][column], rotation: -0.05 },
            {
              duration: [40, 35, 26][column],
              y: [1400, -1200, -1200][column],
              rotation: 0.05,
              ease: "none",
            }
          )
          .progress((i % 6) / 6);
        // setLodedImages((prev) => [...prev, props.images[i].id]);
      }

      function pauseBoxes(box) {
        var classStr = "pb-col0";
        if (box.classList.contains("pb-col1")) classStr = "pb-col1";
        if (box.classList.contains("pb-col2")) classStr = "pb-col2";
        var mainBoxChildren = mainBoxes.children;
        for (var i = 0; i < mainBoxChildren.length; i++) {
          var child = mainBoxChildren[i];
          if (child.classList.contains(classStr)) {
            gsap.to(child.tl, { timeScale: 0, ease: "sine" });
          }
        }
      }

      function playBoxes() {
        var mainBoxChildren = mainBoxes.children;
        for (var i = 0; i < mainBoxChildren.length; i++) {
          var tl = mainBoxChildren[i].tl;
          tl.play();
          gsap.to(tl, {
            duration: 0.4,
            timeScale: 1,
            ease: "sine.in",
            overwrite: true,
          });
        }
      }

      var _tl = gsap
        .timeline({ onStart: playBoxes })
        .set(".main2", {
          perspective: 800,
          height: "120%",
          width: "100%",
        })
        .set(".photoBox", { opacity: 1, cursor: "pointer" })
        .set(".mainBoxes", {
          left: window.screen.width > 1400 ? "45%" : "20%",
          xPercent: 0,
          rotationX: 0,
          rotationY: 0,
          rotationZ: 0,
        })
        .set(".mainClose", {
          autoAlpha: 0,
          width: 60,
          height: 60,
          left: -30,
          top: -31,
          pointerEvents: "none",
        })
        .fromTo(
          ".main2",
          { autoAlpha: 0 },
          { duration: 0.6, ease: "power2.inOut", autoAlpha: 1 },
          0.2
        );

      var photoBoxes = document.querySelectorAll(".photoBox");

      function handleMouseEnter(e) {
        if (currentImg) return;
        if (delayedPlay) delayedPlay.kill();
        pauseBoxes(e.currentTarget);
        var _t = e.currentTarget;
        gsap.to(".photoBox", {
          duration: 0.2,
          overwrite: "auto",
          opacity: function (i, t) {
            return t === _t ? 1 : 0.33;
          },
        });
        gsap.fromTo(
          _t,
          { zIndex: 100 },
          { duration: 0.2, scale: 0.62, overwrite: "auto", ease: "power3" }
        );
      }

      function handleMouseLeave(e) {
        if (currentImg) return;
        var _t = e.currentTarget;

        if (gsap.getProperty(_t, "scale") > 0.62) {
          delayedPlay = gsap.delayedCall(0.3, playBoxes);
        } else {
          playBoxes();
        }

        gsap
          .timeline()
          .set(_t, { zIndex: 1 })
          .to(
            _t,
            {
              duration: 0.3,
              scale: 0.5,
              overwrite: "auto",
              ease: "expo",
            },
            0
          )
          .to(
            ".photoBox",
            { duration: 0.5, opacity: 1, ease: "power2.inOut" },
            0
          );
      }

      function handleClick(e) {
        if (!isZooming) {
          isZooming = true;
          gsap.delayedCall(0.1, function () {
            isZooming = false;
          });

          if (currentImg) {
            gsap
              .timeline({ defaults: { ease: "expo.inOut" } })
              .to(
                ".mainClose",
                { duration: 0.1, autoAlpha: 0, overwrite: true },
                0
              )
              .to(
                ".mainBoxes",
                {
                  duration: 0.5,
                  scale: 1,
                  width: "100%",
                  left: window.screen.width > 1400 ? "45%" : "20%",
                  xPercent: 0,
                  rotationX: 0,
                  rotationY: 0,
                  rotationZ: 0,
                  overwrite: true,
                },
                0
              )
              .to(
                ".photoBox",
                { duration: 0.6, opacity: 1, ease: "power4.inOut" },
                0
              )
              .to(
                currentImg,
                {
                  duration: 0.6,
                  width: 400,
                  height: 640,
                  borderRadius: 20,
                  x: currentImgProps.x,
                  y: currentImgProps.y,
                  scale: 0.5,
                  rotation: 0,
                  zIndex: 1,
                },
                0
              );
            playBoxes();
            dispatch(stylesActions.setCurrentImageOpened());
            currentImg = undefined;
          } else {
            pauseBoxes(e.currentTarget);
            dispatch(stylesActions.setCurrentImageOpened());

            currentImg = e.currentTarget;
            currentImgProps.x = gsap.getProperty(currentImg, "x");
            currentImgProps.y = gsap.getProperty(currentImg, "y");

            gsap
              .timeline({ defaults: { duration: 0.6, ease: "expo.inOut" } })
              .set(currentImg, { zIndex: 100 })
              .fromTo(
                ".mainClose",
                { x: mouse.x, y: mouse.y, background: "rgba(0,0,0,0)" },
                { autoAlpha: 1, duration: 0.3, ease: "power3.inOut" },
                0
              )
              .to(".photoBox", { opacity: 0 }, 0)
              .to(
                currentImg,
                {
                  width: "100%",
                  height: "100%",
                  borderRadius: 0,
                  x: 0,
                  top: 0,
                  y: 0,
                  scale: 1,
                  opacity: 1,
                  // rotation: 90,
                  left: 0,
                  right: 0,
                },
                0
              )
              .to(
                ".mainBoxes",
                {
                  duration: 0.5,
                  left: "0%", //50%
                  width: "100%",
                  rotationX: 0,
                  rotationY: 0,
                  rotationZ: 0,
                },
                0.15
              )
              .to(
                ".mainBoxes",
                { duration: 5, scale: 1.06, rotation: 0.05, ease: "none" },
                0.65
              );
          }
        }
      }

      function handleMouseMove(e) {
        mouse.x = e.x;
        mouse.y = e.layerY;
        if (currentImg)
          gsap.to(".mainClose", {
            duration: 0.1,
            x: mouse.x,
            y: mouse.y,
            overwrite: "auto",
          });
      }

      for (var i = 0; i < photoBoxes.length; i++) {
        photoBoxes[i].addEventListener("mouseenter", handleMouseEnter);
        photoBoxes[i].addEventListener("mouseleave", handleMouseLeave);
        photoBoxes[i].addEventListener("click", handleClick);
      }

      if (!!("ontouchstart" in window)) {
        mouse.x = window.innerWidth - 50;
        mouse.y = 60;
      } else {
        document
          .querySelector(".main2")
          .addEventListener("mousemove", handleMouseMove);
      }
    } else {
      var currentImg = undefined,
        currentImgProps = { x: 0, y: 0 },
        isZooming = false,
        column = -1,
        mouse = { x: 0, y: 0 },
        delayedPlay;

      for (var i = 0; i < props.images.length; i++) {
        if (i % 9 === 0) column++;

        var encodedFilePath = props.images[i].mobile_file_path.replace(/\s/g, "%20");
        var b = document.createElement("div");
        var mainBoxes = document.querySelector(".mainBoxes");
        mainBoxes.appendChild(b);
        gsap.set(b, {
          attr: { id: "b" + i, class: "photoBox pb-col" + column },
          backgroundImage: `url(${encodedFilePath})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
          x: [0, 210, 420][column],
          width: 200,
          height: 340,
          borderRadius: 20,
          scale: 0.9,
          zIndex: 1,
        });

        b.tl = gsap
          .timeline({ paused: true, repeat: -1 })
          .fromTo(
            b,
            { y: [-575, 800, 800][column], rotation: -0.05 },
            {
              duration: [40, 35, 26][column],
              y: [2400, -2200][column],
              rotation: 0.05,
              ease: "none",
            }
          )
          .progress((i % 9) / 9);
      }

      function pauseBoxes(box) {
        var classStr = "pb-col0";
        if (box.classList.contains("pb-col1")) classStr = "pb-col1";
        if (box.classList.contains("pb-col2")) classStr = "pb-col2";
        var mainBoxChildren = mainBoxes.children;
        for (var i = 0; i < mainBoxChildren.length; i++) {
          var child = mainBoxChildren[i];
          if (child.classList.contains(classStr)) {
            gsap.to(child.tl, { timeScale: 0, ease: "sine" });
          }
        }
      }

      function playBoxes() {
        var mainBoxChildren = mainBoxes.children;
        for (var i = 0; i < mainBoxChildren.length; i++) {
          var tl = mainBoxChildren[i].tl;
          tl.play();
          gsap.to(tl, {
            duration: 0.4,
            timeScale: 1,
            ease: "sine.in",
            overwrite: true,
          });
        }
      }

      var _tl = gsap
        .timeline({ onStart: playBoxes })
        .set(".main2", {
          perspective: 800,
          height: "120%",
          width: "100%",
        })
        .set(".photoBox", { opacity: 1, cursor: "pointer" })
        .set(".mainBoxes", {
          left: "0%",
          xPercent: 0,
          rotationX: 0,
          rotationY: 0,
          rotationZ: 0,
        })
        .set(".mainClose", {
          autoAlpha: 0,
          width: 60,
          height: 60,
          left: -30,
          top: -31,
          pointerEvents: "none",
        })
        .fromTo(
          ".main2",
          { autoAlpha: 0 },
          { duration: 0.6, ease: "power2.inOut", autoAlpha: 1 },
          0.2
        );

      var photoBoxes = document.querySelectorAll(".photoBox");

      function handleMouseEnter(e) {
        return;
      }

      function handleMouseLeave(e) {
        if (currentImg) return;
        var _t = e.currentTarget;

        if (gsap.getProperty(_t, "scale") > 0.62) {
          delayedPlay = gsap.delayedCall(0.3, playBoxes);
        } else {
          playBoxes();
        }

        gsap
          .timeline()
          .set(_t, { zIndex: 1 })
          .to(
            _t,
            {
              duration: 0.3,
              scale: 0.9,
              overwrite: "auto",
              ease: "expo",
            },
            0
          )
          .to(
            ".photoBox",
            { duration: 0.5, opacity: 1, ease: "power2.inOut" },
            0
          );
      }

      function handleClick(e) {
        return;
      }

      function handleMouseMove(e) {
        mouse.x = e.x;
        mouse.y = e.layerY;
        if (currentImg)
          gsap.to(".mainClose", {
            duration: 0.1,
            x: mouse.x,
            y: mouse.y,
            overwrite: "auto",
          });
      }

      for (var i = 0; i < photoBoxes.length; i++) {
        photoBoxes[i].addEventListener("mouseenter", handleMouseEnter);
        photoBoxes[i].addEventListener("mouseleave", handleMouseLeave);
        photoBoxes[i].addEventListener("click", handleClick);
      }

      if (!!("ontouchstart" in window)) {
        mouse.x = window.innerWidth - 50;
        mouse.y = 60;
      } else {
        document
          .querySelector(".main2")
          .addEventListener("mousemove", handleMouseMove);
      }
    }

    // };
  }, []);

  return (
    <div className="main2">
      <div className="mainBoxes fs"></div>
      <div className="mainClose">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          width="100%"
          height="100%"
          fill="none"
        >
          <circle cx="30" cy="30" r="30" fill="#000" opacity="0.4" />
          <path
            d="M15,16L45,46 M45,16L15,46"
            stroke="#000"
            strokeWidth="3.5"
            opacity="0.5"
          />
          <path d="M15,15L45,45 M45,15L15,45" stroke="#fff" strokeWidth="2" />
        </svg>
      </div>
    </div>
  );
};

export default GsapBg;
