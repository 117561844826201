import classes from "../../home/blogs/BlogsDetail.module.css";
//
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
//
import OurTeamCard from "../../../ui/cards/OurTeamCard";
import LearMoreButton from "../../../ui/buttons/LearnMoreButton";
//
import ul from "../../../images/ul.svg";

const TeamMembers = ({ content, content2 }) => {
  return (
    <Container className="!pb-10 xs:!mt-20 !mt-32">
      <Row className="!flex !items-center !justify-center">
        <p className="!text-[18px] !text-[#FDCF00] !uppercase">Team</p>
        <h3 className="xs:!text-[25px] !text-[36px] !font-bold !text-white !relative">
          Meet Our Creative Team
          <img
            className="!mt-3 !absolute !left-[50%] transform -translate-x-1/2 -translate-y-1/2"
            src={ul}
            alt="design"
          />
        </h3>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} lg={8} xl={7} xxl={7}>
          <div
            className={`!mt-6 !grid xs:!grid-cols-1 sm:!grid-cols-2 md:!grid-cols-1 !grid-cols-2 !place-items-center !gap-3 !ml-3`}
            id="tAnim"
          >
            {content?.map((t, i) => (
              <OurTeamCard
                key={t.id}
                img={t.main_image}
                name={t.name}
                role={t.position}
                linkedIn={t.linkedin}
              />
            ))}
          </div>
        </Col>
        <Col className="!px-0">
          <Row className="xs:!px-4 sm:!px-4 !pr-5 !mt-5 !w-[100%] !m-0">
            {content2?.description && (
              <div
                className={`${classes.ckDescription}`}
                dangerouslySetInnerHTML={{
                  __html: content2?.description,
                }}
              />
            )}
          </Row>
          <Row>
            <LearMoreButton to="/contact-us" className="!mt-5 !w-1/3 !m-auto">
              Contact Us
            </LearMoreButton>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default TeamMembers;
